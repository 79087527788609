import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { Observable, first, tap } from 'rxjs';
import { AssetCategoryModel, BrandModel } from 'src/app/_models/subjects';
import { AssetService } from 'src/app/_services/asset.service';
import { BrandService } from 'src/app/_services/brand.service';
import { CategoryService } from 'src/app/_services/category.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent {
  activeBID: number|null = null;
  $brand: Observable<BrandModel>;
  $categories: Observable<AssetCategoryModel[]>;  
  categories: AssetCategoryModel[] = [];
  categoriesGlobal: AssetCategoryModel[] = [];
  constructor(
    private brandService: BrandService,
    private assetService: AssetService,
    private categoryService: CategoryService
  ) { }

  ngOnInit(): void {
    this.$brand = this.brandService.activeBrand.pipe(tap( brand => {
      this.activeBID = brand.id;
      this.$categories = this.categoryService.categories().pipe(tap( res => {
        this.categories = res.filter( c => c.global === false);
        this.categoriesGlobal = res.filter( c => c.global === true);
      
      }));
    }));    
    //this.$categories = this.assetService.categories({bid: this.brandService.activeBrandID});
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    let start = this.categoriesGlobal.length +1;
    this.categories.forEach( (c, i) => {
      c.order = start + i;
    });

    let orders = this.categories.map( (c, i) => ({id: c.id, order: c.order}));
    this.categoryService.updateOrder({order: orders});
  }

  add(){
    this.categoryService.edit({order: this.categories.length+1} as AssetCategoryModel);
    // let cat = {
    //   name: 'New Category', 
    //   id: 5, 
    //   order: this.categories.length+1
    // };
    //this.categoryService.add(cat);
  }

  edit(category:AssetCategoryModel){
    this.categoryService.edit(category);
  }

  delete(category:AssetCategoryModel){
    this.categoryService.delete(category);
  }

  activeCategory(category:AssetCategoryModel){
    this.categoryService.activeCategory(category);
  }
}
