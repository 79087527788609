import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandModel, UserProfileModel } from 'src/app/_models/subjects';
import { BrandService } from 'src/app/_services/brand.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/enviorments/enviorment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  $userProfile: Observable<UserProfileModel>;
  cdn = environment.cdn;
  brand: number|false = false;
  $brands: Observable<any>;
  openSettings: boolean = false;
  constructor(
    private userService: UserService,
    private brandService: BrandService
  ) { }

  ngOnInit(): void {
    this.$brands = this.brandService.brands;
    this.brandService.activeBrand.subscribe(brand => {
      this.brand = brand.id;
    });    
    this.$userProfile = this.userService.userProfile
  }

  openSettingsMenu() {
    console.log('openSettingsMenu')
    this.openSettings = !this.openSettings;
  }

  activateBrand() {
    //@ts-ignore
    this.brandService.selectBrand(this.brand);
  }

  ngOnDestroy() {
   
  }
  
}
