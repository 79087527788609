import { Injectable } from '@angular/core';
import { DialogData, DialogFiles, FileModel } from '../_models/subjects';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject, first } from 'rxjs';
import { AssetUploadDialogComponent } from '../_components/assets/asset-upload-dialog/asset-upload-dialog.component';
import { ApiService } from './api.service';
import { IoService } from './io.service';
//@ts-ignore
import * as CryptoJS from 'crypto-js';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../enviorments/enviorment';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ProcessingComponent } from '../_components/assets/processing/processing.component';
import { BrandService } from './brand.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  bucket
  bucketName: string = 'velvetysoft';
  FileBroker: Subject<FileModel[]> = new Subject<FileModel[]>();
  FileProgress: Subject<any> = new Subject<any>();
  AssetProcessing: Subject<any> = new Subject<any>();
  AssetProcessingOpen: boolean = false;
  ACTIVE_BID: number|null = null;

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private io: IoService,
    private snackBar: MatSnackBar,
    private brandService: BrandService,
  ) {
    //@ts-ignore
    this.bucket = new S3({
        accessKeyId: environment.awsid,
        secretAccessKey: environment.awssecret,
        region: environment.awsregion
    });

    this.brandService.$activeBrand.subscribe((brand) => {
      this.ACTIVE_BID = brand.id;
    });

    this.io.assetProcessing().subscribe((data:any) => {
      this.openAssetProcessing()
      this.AssetProcessing.next(data);
    });
       
  }

  pendingUploads(data: any) {
    //this.dialog.open(AssetUploadDialogComponent, {});
    this.openUploader({pendingUploads: data});
  }

  addFiles(_files: any) {
    //CREATE SHELLS
    const iteneraryList: any = {}
    const fileShells = Array
      .from<File>(_files)
      .map((file: File) => {        
        const shell =  new FileShell({file: file},this.ACTIVE_BID);        
        iteneraryList[file.name] = shell.ufi || ''
        return shell;
      });    

    //UPLOAD DIRECT FILES to AWS
    this.uploadFiles(fileShells);    

    //SEND STATUS OF FILES TO BROWSER SERVICE
    this.FileBroker.next(fileShells);

    //UPLOAD INDIRECT    
    //this.uploadFilesIndirect(_files, iteneraryList);
    
  }

  //UPDATE FILE PROGRESS BEFORE ADDING
  addForUploadProgress(data:any){
    this.FileProgress.next(data)
  }

  //LISTEN FOR FILE PROGRESS FROM UPLOAD
  listenForUploadProgress() {
    return this.FileProgress.asObservable();
  }

  //LISTEN FOR FILE added to be uploaded
  whenFilesAdded() {
    return this.FileBroker.asObservable();
  }

  openUploader(data:{files?: FileModel[], uploadRef?: any, pendingUploads?: any}) {
    const { files, uploadRef, pendingUploads } = data;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = <DialogFiles> { 
      fileShells: files, 
      pendingUploads: pendingUploads,
      services: { 
        fileService: this,
        io: this.io,
        $uploadRef: uploadRef}      
    };
    dialogConfig.backdropClass = 'asset-upload-backdrop';
    dialogConfig.id = 'asset-uploader-modal';
    dialogConfig.disableClose = true;
    return this.dialog.open(AssetUploadDialogComponent, dialogConfig);
  }

  uploadFiles(fileShells: FileModel[]) {
  
    const self = this;    

    for (let i = 0; i < fileShells.length; i++) {
      try{
        const _shell = fileShells[i];
        const params = {
          Bucket: this.bucketName,
          Key: `pending/${_shell.filename}`,
          Body: _shell.getFile(),
          ContentType: _shell.type
        };

        const upload = this.bucket.upload(params);        
        const startTime = Date.now(); 

        const calculateAndSendSpeed = (progress:any) => {
            const currentTime = Date.now();
            const elapsedTime = currentTime - startTime;
            const uploadSpeed = (progress.loaded || 0) / (elapsedTime / 1000); // Upload speed in bytes per second
            // Send upload speed over WebSocket
            return uploadSpeed;
        };      
        
        upload.on('httpUploadProgress', function (progress:any) {
          const speed = calculateAndSendSpeed(progress);
          _shell.speed = speed;
          _shell.status = 'uploading';
          if(progress.loaded) self.addForUploadProgress({progress, ufi: _shell.ufi, speed: _shell.speed, status: _shell.status});
        });
          

        upload.send(function(err:any, data:{ Bucket: string, ETag: string, Key : string, Location: string}) {
            if (err) {
                self.addForUploadProgress({ status: err.code, ufi: _shell.ufi, error: true});
            } else {
                _shell.status = 'uploaded';
                _shell.path = data.Key;
                self.addForUploadProgress({ status: _shell.status, ufi: _shell.ufi, progress: 100, path: _shell.path });                
                self.io.uploadedFile( _shell);
            }
        });
      }catch(e){
        console.log('Error', e);
      }
    }
    
  }

  openAssetProcessing() {
    if(this.AssetProcessingOpen) return;
    this.AssetProcessingOpen = true;

    const config: MatSnackBarConfig = {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['asset-processing-snackbar']
    };
    
    const processingRef = this.snackBar.openFromComponent(ProcessingComponent, config);    

    processingRef.afterDismissed().subscribe(() => {
      this.AssetProcessingOpen = false;
    });
  }
  
}


//////////////////////////////////////////
//  File Class
//////////////////////////////////////////
export class FileShell implements FileModel{
  // Properties
  
  ufi: string|null = null;
  presignurl: string|null = null;
  type: string|null = null;
  size: string|number = 0;
  progress: number|null = null;
  ext: string;
  filename: string = '';
  progressStatus: string|null = null;
  speed: string|null = null;
  error: false; 
  imageUrl: string | null = null;
  originalFileName: string | null = null;

  // Pending
  path: string | null = null;
  title: string |null = null;
  status: string |null = null;
  date: Date | null = null;
  bid: number | null = null;

  
  private file: File;
  

  constructor(data:any, bid:number|null) {
    const { file, date, status, ufi, path, imageUrl, title, size, ext } = data;
    this.title = file?.originalname || file?.name || file?.title || title || null; 
    this.originalFileName = file?.originalname || file?.name || file?.title || title || null;   
    this.file = file;
    this.type = file?.type || null;
    this.ufi = ufi || `${new Date().getTime()}${Math.random().toString(36).substring(2, 8)}`;
    this.ext = ext || file?.name.split('.').pop();
    this.filename = `${this.ufi}.${this.ext}`;
    this.file = file;
    //Pending
    this.size = size || file.size;
    this.status = status;
    this.date = new Date();
    this.path = path;
    this.bid = bid || null;
        

    if(file && file.type.match(/image\/*/)){
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    }       

  }


  getShellSummary(){
    return {      
      ufi: this.ufi,
      path: this.path,
      type: this.type,
      size: this.size,
      ext: this.ext,
      filename: this.filename,
      bid: this.bid,
      status: this.status,
      date: this.date,      
      speed: this.speed,
      title: this.title,
    }
  }

  getFile(){
    return this.file;
  }

  
  
}


