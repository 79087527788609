import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VelvetysoftService {

  constructor() { }

  resolve() {
    return this.loadSettings();
  }

  loadSettings() {
    console.log('loadSettings')
  }

  size(bytes:number, separator = " ", postFix = "") {
    if (bytes == 0) return "0 KB";
    if (bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.min(
        parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10),
        sizes.length - 1
      );
      return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${
        sizes[i]
      }${postFix}`;
    }
    return "n/a";
  }

  ranges(){
    return [
      {key: '1d', label: "Today"},
      {key: '1w', label: "1 Week"},
      {key: '1m', label: "1 Month"},
      {key: '3m', label: "3 Months"},
      {key: '6m', label: "6 Months"},
      {key: '1y', label: "1 Year"},    
    ];  
  }
}
