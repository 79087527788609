import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, debounceTime, distinctUntilChanged, first, map, merge, switchMap } from 'rxjs';
import { AssetModel, Pager, DialogData } from '../_models/subjects';
import { ApiService } from './api.service';
import { environment } from 'src/enviorments/enviorment';
import { AssetEnlargeComponent } from '../_components/assets/asset-enlarge/asset-enlarge.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VelvetysoftService } from './velvetysoft.service';
import { IoService } from './io.service';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  subject = 'asset';
  cdn = environment.cdn;
  assetcdn = environment.assetcdn;
  activePager: any;
  private IOID:string = 'Asset';
  public $assetPager: ReplaySubject<Pager<AssetModel>> = new ReplaySubject(1);
  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    private vs: VelvetysoftService,
    private ioService: IoService
  ) {    
    // this.$bRef = this.brandService.activeBrand.subscribe((brand:BrandModel) => {      
    //   this._cacheBID = brand.id;
    //   this.loadCategories({bid: brand.id});
    // });
    // this.$ioRef = this.io.$status.pipe( 
    //   switchMap(res => {        
    //     this.SYSTEM = (res.status) ? 'IO' : 'HTTP';              
    //     return (this.SYSTEM == 'IO')
    //       ? this.io.listen<AssetCategoryModel>(this.IOID,this.$categories)
    //       : EMPTY;        
    // }),
    // map(() => {})
    // ).subscribe();
  }

  pager<T>(data?:any,){
    return this.api.pager<T>(`${this.subject}s-pager`,data);
  }

  pager2<T>(data?:any){
    //this.activePager = 
    //
    this.activePager = new Pager2<T>({
      path: `${this.subject}s-pager`,
      ...data
    }, this.api);
    this.ioService.listen<AssetModel>(this.IOID,this.activePager.$source)
    return this.activePager
  }

  add(assets:AssetModel[]){
    return this.api.post(`${this.subject}s-add`, { assets:assets })
  }

  

  thumb(asset:AssetModel){
    
    if(asset.g_icon){
      //let thumb = this.gapiService.thumb(asset);  
      //console.log(thumb)    
      return asset.g_icon; 
        // ? thumb
        // : asset.g_icon;
    }

    let cdn  = this.cdn;

    //IF PDF CHANGE FILE EXTENSION FOR THUMB

    //JACKPOT STUFF
    let path = '';
    if(asset.type == 'jackpot-asset'){
      cdn = this.assetcdn;
      path = asset.path.replace("assets", "thumb")
      path = path.replace(/\.[^/.]+$/, ".webp");      
    }else{
      path = ( asset.path && asset.path.includes(".pdf") )
        ? asset.path.replace("\.pdf", ".jpg")
        : asset.path;      
      path = path.replace("original", "thumb");
      //legacy showpony replacement      
      if(['psd','mp4'].includes(asset.ext)) path = path.replace(/\.[^/.]+$/, ".webp");

    }
      
    
    return (asset.hasThumb)
      ? cdn + path
      : null;
  }

  downloadAsset(asset:AssetModel):void{

    //GASSET EXCEPTION
    if(asset.g_id){
      window.open(asset.path);
    } 
    
    this.api.get(`download/${asset.id}`)
      .pipe(first())
      .subscribe(
        (response) => {
          window.open(response);
        });
  }

  enlarge(asset:AssetModel){
    let resultRef = new Subject();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = <DialogData> { 
      model: asset, 
      services: { assetService: this },
      type: 'asset'
    };
    // //DIALOG CONFIG
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = false;
    // dialogConfig.autoFocus = false;        
    // dialogConfig.data = {};
   
    // dialogConfig.data[this.subject] = subject;
    // dialogConfig.data[`${this.subject}Service`] = this;   
    // dialogConfig.data['options'] = options;
    dialogConfig.id = 'enlarge-gallery';
    const dialogRef = this.dialog.open(AssetEnlargeComponent, dialogConfig);
    
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
      });
    return resultRef;
  }

  log(asset:AssetModel){
    return this.ioService.assetLog(asset);
  }

  remakeThumb(asset:AssetModel){
    return this.api.get(`assets-rethumb/${asset.id}`);
  }

  details(asset:AssetModel){
    return this.api.get(`assets-details/${asset.id}`);
  }

  viewVideo(asset:AssetModel){
      return this.api.get(`asset-preview-video/${asset.id}`);
  }

  size = this.vs.size;

  //NERV  


  
  

}


export class Pager2<T> {
  private originalPath: string;
  private lastPath: string;
  private filters: any = {};
  $source: ReplaySubject<Pager<T>> = new ReplaySubject(1);

  private querySubject = new Subject<any>();

  constructor(
    data: any,
    private api: ApiService
  ) {
    const { path, filters } = data;
    this.originalPath = path;
    console.log('PAGER2 :: INIT', data);

    this.querySubject.pipe(
      debounceTime(300), // Adjust debounce time as needed
      distinctUntilChanged(), // Only trigger if filters have changed
      switchMap((data: any) => {
        //return query and data here
        return this.queryInternal(data) 
      }))
      .subscribe( pager => {
        if (data.options && data.options.persist) {           
          this.$source.pipe(first()).subscribe(previousPager => {
            console.log("persist");
            const mergedData = [...previousPager.data, ...pager.data];
            this.querySubject.next(data);
          });
        } else {
          this.$source.next(pager)
        }
        
    });
    
    this.querySubject.next({ path: path, filters: filters });
  }

  query(data: any) {
    //const { path, filters, options } = data;
    
    //if (options && options.persist) {            
      // this.$source.pipe(first()).subscribe(previousPager => {
      //   console.log("persist");
      //   const mergedData = [...previousPager.data, ...pager.data];
      //   this.querySubject.next(data);
      // });
    //} else {
      this.querySubject.next(data);
    //}
    
  }

  private queryInternal(data: any) {
    const { path, filters, options } = data;

    this.setFilter(filters)

    let finalPath = this.lastPath = (path)
      ? path.replace(this.api.api, '')
      : this.originalPath;

    let stringfilters = this.getFilter();

    console.log('PAGER2 :: QUERY', finalPath, stringfilters);

    this.lastPath = `${finalPath}${stringfilters}`;

    return this.api.post(this.lastPath, {})
      .pipe(first());
      //,map( pager => [pager, data] 
  }

  refresh() {
    let path = (this.lastPath)
      ? this.lastPath
      : this.originalPath;
    this.query({ path: path });
  };

  private setFilter(data: any) {
    this.filters = data;
  }

  private getFilter() {
    const parts: string[] = []; // Explicitly type the array as an array of strings
    for (const key in this.filters) {
      if (this.filters.hasOwnProperty(key) && this.filters[key] !== null && this.filters[key] !== undefined) {
        const value = Array.isArray(this.filters[key]) ? this.filters[key].join(',') : this.filters[key]; // Handle arrays
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
    return parts.length > 0 ? `?${parts.join('&')}` : '';
  }

  nextPage(data?: any) {
    this.$source.pipe(first())
      .subscribe(pager => this.query({ path: pager.next_page_url, ...data }));
  }

  prevPage() {
    this.$source.pipe(first())
      .subscribe(pager => this.query({ path: pager.next_page_url }));
  }

}
