import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NervResponseImg } from '../_models/subjects';
import { VelvetysoftService } from '../_services/velvetysoft.service';


@Pipe({
  name: 'nervImg'
})
export class nervImgPipe implements PipeTransform {

  constructor(private apiService: ApiService) { }

  transform(url:string): Observable<NervResponseImg> {
    return this.apiService.nervImg(url) as Observable<NervResponseImg>;
  }

}


@Pipe({
  name: 'filterByProperty',
  pure: false // Set the pipe to be impure

})
export class FilterByPropertyPipe implements PipeTransform {
  transform(items: any[], property: string, value: any): any[] {
    if (!items || !property || typeof value == undefined) {
      return items;
    }

    return items.filter(item => item[property] === value );
  }
}

@Pipe({
  name: 'size',  
})
export class SizePipe implements PipeTransform {
  constructor(private vs: VelvetysoftService) { }
  transform(value: any): string {
    if (!value) {
      return value;
    }

    return this.vs.size(value);
  }
}


@Pipe({
  name: 'sortBy',
  pure: false
})
export class SortByPipe implements PipeTransform {
  transform(array: any[], property: string): any[] {
    if (!Array.isArray(array) || !property) {
      return array;
    }

    return array.slice().sort((a, b) => {
      if (a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
      return 0;
    });
  }
}