<div class="asset-details" [class.open]="open">
    
    <div class="p-4" *ngIf="data">
        <h5 class="mb-3 text-uppercase color-white">Asset {{ data.action }}</h5>

        <ng-container *ngIf="data.action == 'log' && ($results | async); let results">
            <div class="row row-item pb-2 mt-2" *ngFor="let result of results | keyvalue | sortBy: 'order'">
                <div class="col-sm-4 label-subtle text-uppercase">{{ result.key }}:</div>    
                <div class="col-sm-8 label  color-white">{{ display(result.value) }}</div>            
            </div>
        </ng-container>

        <ng-container *ngIf="data.action == 'details' && ($results | async); let results">
            <div>
                <div *ngIf="!data.asset.hasThumb"> <button mat-flat-button (click)="makeThumb(data.asset)">reGenerate Thumb</button></div>
            </div>
            <hr>
            <div class="row row-item" *ngFor="let item of results">
                <div class="col-sm-12 mb-2 mt-2 " *ngIf="!item.hidden">
                    <div class="label-subtle text-uppercase">{{ item.label }}:</div>    
                    <div class="label  color-white">
                        <div *ngIf="item.valueLabel; else value">{{ item.valueLabel }}</div>
                        <ng-template #value>
                            {{ item.value}}
                        </ng-template>
                    </div>            
                </div>                
            </div>
        </ng-container>
    </div>
</div>