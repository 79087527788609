import { Component } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { AssetModel, Pager, PagerObject, UserProfileModel } from 'src/app/_models/subjects';
import { AssetService } from 'src/app/_services/asset.service';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent {
  $userProfile: Observable<UserProfileModel>;
  $assets:Observable<Pager<AssetModel>>;
  assetspager: PagerObject;

  url: string = '';
  data: any = {};
  search: string = '';
  loading: boolean = true;
  query: Pager<AssetModel>;
  private $search = new Subject<any>();

  constructor(
    private assetService: AssetService,
  ) { }

  ngOnInit(): void {
    // this.assetspager = this.assetService.pager<AssetModel>();
    // this.$assets = this.assetspager.$source.pipe(tap(assets => {
    //   this.query = assets
    //   this.loading = false;
    // }));
  }

  onScroll(event: any) {      
    //@TODO HARD CODED HEIGHT , FIX
    let frame = event.target.scrollTop+window.innerHeight-60;
    // if(frame >= event.target.scrollHeight) 
    //   this.doQuery({},this.query.next_page_url,{persist:true})
    
  }

 

  doQuery(data?:any, url?:string, options?:any){      
    // this.$search.next({
    //   data: data,
    //   url: url,
    //   options: options
    // }); 
    //this.assetspager.loadSearch({},this.query.next_page_url);
  }

  
  next(){
    //console.log('Next',this.query.next_page_url);
   // this.doQuery({},this.query.next_page_url)
  }

  prev(){
    //this.doQuery({},this.query.prev_page_url)
  }

  onSearch(){
    //this.doQuery({search:this.search})
  }

  ngDestroy(){
    this.$search.complete();
  }
}
