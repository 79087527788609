

<div class="assets-sidebar">
  <div class="py-3 ps-3">
    <button mat-raised-button color="primary" (click)="addAsset()"><mat-icon>add</mat-icon>  Add Asset</button>
  </div>

  <ng-container *ngIf="($categories | async); let categories; else loader">
    <div class="d-flex flex-column flex-shrink-0">    
      <mat-accordion multi>           
        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div>
                        <div class="label text-uppercase ">Categories</div>   
                    </div>
                </mat-panel-title>            
            </mat-expansion-panel-header>
            <ul class="nav nav-pills flex-column mb-auto">
                <li *ngFor="let cat of categories | filterByProperty: 'active': true  trackBy: order">
                  <button mat-button class="nav-link" (click)="category(cat)">{{ cat.name }} </button>
                </li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <div>
                      <div class="label text-uppercase ">Filters</div>   
                  </div>
              </mat-panel-title>            
          </mat-expansion-panel-header>
          <ul class="nav nav-pills flex-column mb-auto">
              <li >
                <button mat-button class="nav-link">Type</button>
              </li>
          </ul>
        </mat-expansion-panel>

      </mat-accordion>
    </div>

    <!-- <ng-template #create>
        <button mat-button [routerLink]="'/settings/categories'" class="nav-link">Create Category</button>    
    </ng-template> -->

  </ng-container>

</div>

  <ng-template #loader>
    <app-loader></app-loader>
  </ng-template>