import { Component } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscription, forkJoin, switchMap, tap } from 'rxjs';
import { BrandModel, BrandOverviewModel } from 'src/app/_models/subjects';
import { AssetService } from 'src/app/_services/asset.service';
import { BrandService } from 'src/app/_services/brand.service';
import { CategoryService } from 'src/app/_services/category.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';

interface RangeModel {
  key: string;
  label: string;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  activeBID: number|null = null;
  $brand: Observable<BrandModel>;
  $overview: Observable<BrandOverviewModel> | Observable<any>;
  rangeChangeSubject = new ReplaySubject<RangeModel>(1);
  brand: BrandModel;  
  ranges: RangeModel[];
  range: any;
  $hugeChain: Subscription;
  $dashDataRef = new Subject<any>();   
  constructor(
    private brandService: BrandService,
    private vs: VelvetysoftService
  ) {
    this.ranges = this.vs.ranges();
    this.range = this.ranges.find( (r: RangeModel) => r.key == '1m');
  }

  ngOnInit(): void {
    this.$overview = this.loadDash();
    this.rangeChangeSubject.next(this.range);
    // this.getBrandInfo().subscribe( ([brand,overview]) => {
    //   console.log(brand,overview);
    //   //this.activeBID = res[0].id;
    //   //this.$overview = res[1];
    // });
    // this.$brand = this.brandService.activeBrand.pipe(tap( brand => {
    //   this.activeBID = brand.id;
    //   this.$overview = this.brandService.brandOverview();
    // }));    
  }

  updateOverview(){

  }

  loadDash():Observable<BrandOverviewModel> {       
    this.$dashDataRef.next({loading: 'loading...'})   
    this.$hugeChain = this.rangeChangeSubject.pipe(
      switchMap( (range) => {
        this.$dashDataRef.next({loading: 'loading...'})
        return this.getBrandInfo(range);    
      })).subscribe( (overview:BrandOverviewModel) => {
        //set a delya for prettyness
        setTimeout(() => {
          this.$dashDataRef.next(overview);
        }, 400);
      });
    return this.$dashDataRef.asObservable();
  }


  getBrandInfo(range:RangeModel):Observable<BrandOverviewModel>{
    return this.brandService.activeBrand.pipe(
      switchMap( (brand:BrandModel) => {
        this.brand = brand;
        this.activeBID = brand.id;       
        return this.brandService.brandOverview(this.range.key);
      }));
    
  }

  ngOndestroy(){
    this.brandService.$activeBrand.unsubscribe();
    this.$hugeChain.unsubscribe();
  }

  setRange(key:string){
    this.range = this.ranges.find( (r: { key: string; }) => r.key == key);    
    this.rangeChangeSubject.next(this.range);
    //this.$overview = this.brandService.brandOverview(this.range.key);
  }

}
