import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './_components/login/login.component';
import { AssetsComponent } from './_components/assets/assets.component';
import { AuthGuard } from './_guards/auth.guard';
import { VelvetysoftService } from './_services/velvetysoft.service';
import { BrandService } from './_services/brand.service';
import { MainComponent } from './_components/main/main.component';
import { CategoriesComponent } from './_components/settings/categories/categories.component';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { CollectionsComponent } from './_components/collections/collections.component';
import { DashboardSettingsComponent } from './_components/settings/dashboard-settings/dashboard-settings.component'
import { SubscriptionComponent } from './_components/settings/subscription/subscription.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainComponent,
    resolve: { settings: VelvetysoftService, brand: BrandService },
    children: [
      { path: '', redirectTo: 'assets', pathMatch: 'full',  },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'assets', component: AssetsComponent, canActivate: [AuthGuard] },
      { path: 'collections', component: CollectionsComponent, canActivate: [AuthGuard] },
      { path: 'settings/categories', component: CategoriesComponent, canActivate: [AuthGuard]},
      { path: 'settings/dashboard', component: DashboardSettingsComponent, canActivate: [AuthGuard]},
      { path: 'settings/subscription', component: SubscriptionComponent, canActivate: [AuthGuard]}
    ]
  },  
  {
    path: 'login',
    component: LoginComponent,
  },  
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
