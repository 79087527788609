import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CompaniesModel, UserProfileModel } from '../_models/subjects';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _userProfileData: UserProfileModel;
  private $userProfile: ReplaySubject<UserProfileModel> = new ReplaySubject();
  public userProfileBuster = true;
  subject = "users";
  $ctx_user_data: ReplaySubject<any> = new ReplaySubject(1);
  initUserList = false;

  constructor(
    private apiService: ApiService
  ) { }

  get userProfile() {
    if (this.userProfileBuster) {
      this.loadUserProfile();
      this.userProfileBuster = false;
    }
    return this.$userProfile.asObservable();
  }

  loadUserProfile() {
    this.apiService.get<UserProfileModel>("user").subscribe(
      (res:UserProfileModel) => this.formatUserProfile(res)
      //,(error) => this.handleError(error)
    );
  }

  private formatUserProfile(res:any) {
    //@TODO CHECK IF NO COMPANY IS ADDED and make errors
    if (res.companies) {
      let cid = res.user.active_company;
      res.company = res.companies.find((c:CompaniesModel) => c.id === cid);
      //exception if not set
      if (!res.company) res.company = res.companies[0];
    }
    this._userProfileData = res;
    this.$userProfile.next(res);
  }
}
