import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AssetModel, DialogData } from 'src/app/_models/subjects';

@Component({
  selector: 'app-asset-enlarge',
  templateUrl: './asset-enlarge.component.html',
  styleUrls: ['./asset-enlarge.component.scss']
})
export class AssetEnlargeComponent {  
  url: string = "";
  detailsOpen: boolean = false;
  detailsData: any;
  isVideo: boolean = false;
  $videoPreview: Observable<any>;

  constructor(
    public dialogRef: MatDialogRef<AssetEnlargeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.url = this.data.model.path;
    if(this.data.model.ext == 'mp4'){
      this.isVideo = true;
      this.$videoPreview = this.data.services.assetService.viewVideo(this.data.model);
    }
  }

  download(asset: AssetModel){
    this.data.services.assetService.downloadAsset(asset);
  }

  edit(asset: AssetModel){

  }

  meta(action: string){    
    this.detailsData = {
      'asset': this.data.model,
      'action': action,
    }    
    this.detailsOpen = true;    
  }
  

  size(asset: AssetModel){
    return this.data.services.assetService.size(asset);
  }

  gallery(url: string|undefined){
    this.url = url || "";    
  }
}
