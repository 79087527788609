import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {BrandModel, BrandOverviewModel } from '../_models/subjects';
import { Observable, ReplaySubject, first } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class BrandService {
  public BrandsBuster: boolean = true;
  public $activeBrand: ReplaySubject<BrandModel> = new ReplaySubject(1);
  private $brands: ReplaySubject<BrandModel[]> = new ReplaySubject(1);
  private _activeBrandID: number|null = null;
  private _brandsData: BrandModel[] = [];
  private subject: string = "brand";

  constructor(
    private apiService: ApiService,
  ) { }

  get activeBrandID() {
    if (!this._activeBrandID) {
      return false;
    } else {
      return this._activeBrandID;
    }
  }
  get activeBrand() {
    this.getBrandsCache();
    return this.$activeBrand.asObservable();
  }

  get brands() {
    this.getBrandsCache();
    return this.$brands.asObservable();
  }

  private getBrandsCache() {
    if (this.BrandsBuster) {
      this.loadBrands();
      this.BrandsBuster = false;
    }
  }

  resolve() {    
    return this.loadBrands();
  }

  loadBrands(forceReplay?:any) {
    this.apiService.get<BrandModel[]>("brands")
      .pipe(first())
      .subscribe(
      (res) => {
        this._brandsData = res;
        this.$brands.next(res);
        if (!this._activeBrandID || forceReplay) {
          
          let lastBrand = null;
          let lastBrandID = localStorage.getItem("active_brand");
          if (lastBrandID) {
            //@ts-ignore            
            lastBrand = this._brandsData.find(x => x.id == +lastBrandID);
          }
          let selectedBrand = lastBrand ? lastBrand : this._brandsData[0];
          this._activeBrandID = selectedBrand.id;
          this.$activeBrand.next(selectedBrand);
        }
      },
      (error) => {
        if(error && error.status == 401){
          console.log(error, 'HUGE ERROR')
          localStorage.removeItem("token");
          window.location.reload();
        }
      }
    );
  }

  brandOverview(range:string,company?:boolean): Observable<BrandOverviewModel>{
    console.log('brandOverview', this._activeBrandID);
    let data = { 
      bid: this._activeBrandID,
      range: range,
      company: company
    };
    
    return this.apiService.post<any>("brand-overview", data) as Observable<BrandOverviewModel>;
  }

  selectBrand(id:number,skipReload:boolean=false) {
    //let selectedBrand: BrandModel = _.find(this._brandsData, ["id", id]);
    let selectedBrand: BrandModel|undefined = this._brandsData.find((x) => x.id == id);
    if (!selectedBrand) {
      return false;
    }
    this._activeBrandID = selectedBrand.id;
    localStorage.setItem("active_brand", JSON.stringify(this._activeBrandID));
    //if(!skipReload) this.router.navigate(["/dashboard/overview"]);
    return this.$activeBrand.next(selectedBrand);
  }

}
