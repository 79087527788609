import { Component, Input } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AssetCategoryModel } from 'src/app/_models/subjects';
import { CategoryService } from 'src/app/_services/category.service';
import { FileService } from 'src/app/_services/file.service';
import { AssetService } from '../../../_services/asset.service';
import { PagerObject } from '../../../_models/subjects';

@Component({
  selector: 'app-assets-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() pager: PagerObject;
  $categories: Observable<AssetCategoryModel[]>;
  constructor(
    private categoryService: CategoryService,
    private fileService: FileService,
    private assetService: AssetService
  ) { }

  ngOnInit(): void {
    this.$categories = this.categoryService.categories();    
  }

  hasNav(categories:AssetCategoryModel[]){
    return categories.find(cat => cat.active);
  }

  addAsset(){
    this.fileService.openUploader({});
  }

  category(cat: AssetCategoryModel){
    this.pager.query({category:cat.id});
  
  }

  order(index: number, item: AssetCategoryModel){
    return item?.id;
  }
  // public trackItem (index: number, item: AssetCategoryModel) {
  //   return item.order;
  // }
}
