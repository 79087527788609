<ng-container *ngIf="($userProfile | async); let profile; else loader">
    
        <div class="d-flex flex-nowrap" >
            <div class="offset"></div>
            <div class="container mt-4">
                <h3>{{ profile.company.name }}</h3>
                <hr>            

                <div class="row d-flex" >
                    <div class="col-sm-4 tile-wrap active" >
                        <div class="tile p-4 ">
                            <span class="label uc-text">Basic Plan</span>
                            <ul>
                                <li>5 Custom categories</li>
                            </ul>
                        </div>     
                    </div>
                    <div class="col-sm-4 tile-wrap">
                        <div class="tile p-4">
                            <span class="label uc-text">Standard Plan</span>
                            <ul>
                                <li>10 Custom categories</li>
                            </ul>
                        </div>     
                    </div>          
                    <div class="col-sm-4 tile-wrap">
                        <div class="tile p-4">
                            <span class="label uc-text">Premium Plan</span>
                            <ul>
                                <li>15 Custom categories</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</ng-container>

<ng-template #loader>
    <app-loader></app-loader>
</ng-template>