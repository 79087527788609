import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Socket } from 'ngx-socket-io';
import { AssetModel, FileModel, IoStatusModel, NervEventResponse, NervIoSummary, NervResponse } from '../_models/subjects';
import { BehaviorSubject, ReplaySubject, Subject, Subscription, tap, map, Observable } from 'rxjs';
import { PendingUploadsComponent } from '../_components/pending-uploads/pending-uploads.component';
import { MatDialog } from '@angular/material/dialog';
import { VSR } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class IoService {
  isConnected: boolean = false;
  openRequests: string|null = null;
  $status = new ReplaySubject<IoStatusModel>(1);
  constructor(
    private socket: Socket,
    private snack: MatSnackBar,
    private dialog: MatDialog
  ) { 
    this.socket.on('connect', () => {
      this.isConnected = true;
      this.$status.next(this.status());
      console.log('IO - Online')
    });

    this.socket.on('disconnect', () => {
      this.isConnected = false;
      this.$status.next(this.status());
      if(this.openRequests){
        console.log('IO - Offline - Open Request - possible FATAL ERROR', this.openRequests)
        this.snack.open(`You're recent request for  "${this.openRequests}" may have failed. We are restrating a server real fast... `, 'Close', {
          duration: 5000,
        });
      }
      console.log('IO - Offline')
    });

    this.socket.fromEvent<NervResponse>('error').subscribe( data => {
      this.openRequests = null;
      console.log('IO - Error', data);
      this.snack.open(`${data.message}`, 'Close', {
        duration: 2000,
      });
    });

    this.socket.fromEvent<NervResponse>('message').subscribe( data => {
      this.openRequests = null;
      console.log('IO - Message', data);
      this.snack.open(`${data.message}`, 'Close', {
        duration: 2000,
      });
    });

    this.socket.fromEvent<NervResponse>('file:status').subscribe( data => {
      console.log('IO - File Status', data);
      // this.openRequests = null;
      // console.log('IO - Message', data);
      // this.snack.open(`${data.message}`, 'Close', {
      //   duration: 2000,
      // });
    });
  }

  connections(){
    return this.socket.fromEvent<NervIoSummary>('connections');      
  }

  status():any{
    return{
      status: this.isConnected,      
    }
  }

  //FILE UPLOAD
  // uploadProgress(){
  //   return this.socket.fromEvent('uploadprogress');
  // }

  myPendingUploads(){
    return this.socket.fromEvent('file:pending');
  }

  assetProcessing(){
    return this.socket.fromEvent<NervResponse>('file:status');
    // .subscribe( data => {
    //   console.log('IO - File Status', data);
    // });
  }

  //FILE UPLOAD
  uploadedFile(file: FileModel){
    this.socket.emit('file:uploaded', file.getShellSummary());
  }

  // assetDetails(asset: AssetModel){
  //   const ref = new Subject<VSR>();
  //   this.socket.emit('asset:details', asset);
  //   return ref.asObservable();
  // }

  assetLog(asset: AssetModel){
    const ref = new Subject<NervResponse>();
    this.socket.emit('asset:log', asset, (data:any) => ref.next(data));
    return ref.asObservable();
  }

  deletePendingFile(ufi: string){
    const ref = new Subject<VSR>();
    this.socket.emit('file:delete', ufi, (data:any) => ref.next(data));
    return ref.asObservable();
    
  }










  //MEAT AND POTATOES
  // FOR MODEL BASED REST ACTIONS
  listen<T>(model:string, $subject: BehaviorSubject<T[]>){
    return this.socket.fromEvent<NervEventResponse<T>>(model).pipe(
      tap( res => this.listenActions<T>(res, $subject))
    )
  }

  listenActions<T>(res: NervEventResponse<T>, $subject: BehaviorSubject<T[]>): Observable<T[]>|null{  
    //@TODO: EVENTUALL NEED TO ADD A LOOP FOR MANY ACTIONS NOT JUST FIRST ONE
    const action = Object.keys(res)[0];  
    const data:any = Object.values(res)[0];
    const _collection = $subject.getValue();
    const $ref = new Subject<T[]>();
    let collection = _collection;
  
    //UPDATE
    if(action == 'update'){          
      collection = _collection.map( (record:any) => {            
        //GET ITEM
        const newRecord = (Array.isArray(data))            
          ? data.find((d) => d.id == record.id)
          : (data.id == record.id) ? data : null;

        //RETURN NEW RECORD OR OLD
        return (newRecord)  
          ? { ...record, ...newRecord }
          : record;
      });    
    }

    //ADD
    if(action == 'add'){       
      collection = (Array.isArray(data))
        ? [..._collection, ...data]
        : [..._collection, data];  
    }

    //DELETE
    if(action == 'delete'){        
      collection = collection.filter( (record:any) => {
        return (Array.isArray(data))
          ? searchArray(data, record.id) === null
          : data != record.id;
      });
    
    }

    $subject.next(collection);
    $ref.next(collection);
    let difference = _collection.filter(x => !collection.includes(x));
    console.log(`IO - ${action} - Diff`, difference);
    return $ref.asObservable();
  }
  
  
}
  

  
//UTILITIES
const searchArray = (arr: (string | number)[], target: string | number) => {
  const index = arr.indexOf(target);
  return index !== -1 ? index : null; // Return index if found, otherwise null
};
