

<h1 mat-dialog-title>Asset Uploader</h1>

<mat-dialog-content class=""> 

    <div class="text-center py-3 ">
        <app-asset-uploader [files]="this.data.fileShells"></app-asset-uploader>
    </div>

    <ng-container *ngIf="pendingUploads.length > 0">
        <div class="well">
            <div class="label mb-1" >Pending Uploads</div>
            <p>You have {{ pendingUploads.length }} pending upload(s) would you like to load them?</p>
            <button mat-button color="primary" (click)="quePending()">Load Pending Files</button>
            <hr>
        </div>
    </ng-container>

    <ng-container 
        *ngIf="data.fileShells && data.fileShells.length > 0" 
        [ngTemplateOutlet]="filesTemplate" 
        [ngTemplateOutletContext]="{fileShells: data.fileShells}"
    ></ng-container>
    
   

    <form [formGroup]="form" *ngIf="data.fileShells.length > 0">   
        <div class="label mb-1">Asset Properties</div>
        <div>
            <mat-form-field *ngIf="($categories | async ); let categories">
                <mat-label>Category</mat-label>
                <mat-select matNativeControl formControlName="category" [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let category of categories " [value]="category.id">{{ category.name }}</mat-option>
                </mat-select>
                @if (form.controls['category'].hasError('required')) {
                    <mat-error>You must make a selection</mat-error>
                }            
            </mat-form-field>
        </div>
    </form>
    <div>
        <div><span>ASSETS ERROR:</span> {{ formAssets.invalid | json }} : <span (click)="log(formAssets)">{{ formAssets.errors | json }}</span></div>
        <div><span>FORM ERROR:</span> {{ form.invalid | json }} : <span (click)="log(form)">{{ form.errors | json }}</span></div>
    </div>


</mat-dialog-content>

<mat-dialog-actions >    
  <button mat-button mat-dialog-close (click)="cancel()">Cancel</button>  
  <button mat-raised-button mat-dialog-close color="primary" [disabled]="form.invalid || formAssets.invalid" (click)="submit()"><mat-icon>add</mat-icon>Add Assets</button>  
</mat-dialog-actions>



<ng-template #filesTemplate let-fileShells="fileShells" >
        <div class="d-flex p-2 align-items-center row mb-3 shell" *ngFor="let fileShell of fileShells">
            <div class="col-sm-2 p-0">
                <div class="shell-wrap">
                    <img class="shell-preview" *ngIf="fileShell.imageUrl" [src]="fileShell.imageUrl" alt="Preview" />
                    <div *ngIf="!fileShell.imageUrl" class="shell-preview justify-content-center align-items-center d-flex">
                        <mat-icon class="no-preview">image</mat-icon>
                    </div>
                </div>
            </div>
            <div class="col-sm-10">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="file-header pe-3">
                        <div class="label text-truncate" style="max-width: 320px;">{{ fileShell.title }}</div> 
                        <span class="file-size label-subtle">{{ fileShell.size | size }}</span>                    
                    </div>
                    <div class="progress-text text-end">
                        <!-- <div *ngIf="!fileShell.status || fileShell.status == 'uploading'; else status;"> -->
                        <div *ngIf="fileShell.status !== 'uploaded'; else status;">
                            <span *ngIf="fileShell.progress && !fileShell.error">{{ fileShell.progress | number  }}%</span>                            

                            <!-- <div><button mat-button color="warn" (click)="delete(fileShell)">Delete</button></div> -->
                        </div>

                        <!-- <span *ngIf="!fileShell.progress">
                            <span *ngIf="fileShell.error" >Error:</span> 
                            {{ fileShell.status }}
                        </span>        -->

                        <!-- STATUS OCCURES AFTER UPLOAD IS COMPELTE @TODO: for error-->
                        <ng-template #status>
                            <div class="label text-capitalize d-flex align-items-center">
                                <ng-container *ngIf="fileShell.status == 'uploaded'"> <mat-icon class="success me-2">done</mat-icon></ng-container> 
                                <ng-container *ngIf="fileShell.status == 'uploaded'">{{ fileShell.status }}</ng-container>
                            </div>
                            <span class="label label-subtle">{{ fileShell.date | date }}</span>
                            <div><button mat-button color="warn" (click)="delete(fileShell)">Delete</button></div>
                        </ng-template>
                        <div class="file-size label-subtle">{{ fileShell.speed }}</div>
                    </div>
                </div>
                <div *ngIf="!fileShell.status || fileShell.status == 'uploading'">
                    <mat-progress-bar mode="determinate" [value]="fileShell.progress"></mat-progress-bar>
                </div>
                <span [class.mat-mdc-form-field-error]="fileShell.error" *ngIf="fileShell.status && fileShell.status !== 'uploading'"> 
                    <span *ngIf="fileShell.error" >Error: {{ fileShell.status}}</span> 
                    
                </span>
                
            </div>        
        </div>
</ng-template>