import { Component, Inject, Injectable } from '@angular/core';
import { Observable, first, tap } from 'rxjs';
import { BrandModel, NervIoSummary, UserProfileModel } from 'src/app/_models/subjects';
import { BrandService } from 'src/app/_services/brand.service';
import { UserService } from 'src/app/_services/user.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/enviorments/enviorment';
import { IoService } from 'src/app/_services/io.service';
import { FileService } from 'src/app/_services/file.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})


export class MainComponent {
  $userProfile: Observable<UserProfileModel>;
  root = this.document.documentElement;
  $activeSummary: Observable<NervIoSummary>;  
  $myPendingUploads: Observable<any>;
    constructor(
      private brandService: BrandService,
      private userService: UserService,
      private io: IoService,
      private fileService: FileService,
      @Inject(DOCUMENT) private document: Document
    ) {
      console.log('Main Component')
      //this.$activeSummary = this.io.connections();
    }
  
    ngOnInit(): void {      
      //REQUIRED ON INIT TO SET ACTIVE BRANDID
      this.io.myPendingUploads().subscribe((data:any) => {
        if(data) this.fileService.pendingUploads(data);
      });

      this.$userProfile = this.userService.userProfile
      .pipe(tap( 
        user => (user.company.color) && this.root.style.setProperty('--primary',user.company.color)
      ));
      
    }
    
}
