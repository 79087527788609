export const environment = {
  production: false,
  backend: 'https://staging.velvetysoft.com/api/',
  nerv: 'https://staging.nerv.velvetysoft.com/',
  io: 'https://staging.nerv.velvetysoft.com:1338/',
  cdn: 'https://vh-vault.s3.amazonaws.com/',  
  assetcdn: 'https://velvetysoft.s3.us-east-1.amazonaws.com/',
  gApiClientId: "937782570844-6hib5ov0t0rdlfmvq7ndt0bsef7ds7jg.apps.googleusercontent.com",
  gApiKey: "AIzaSyDrE29Sg8x9u65Qzws4MmrJ-agZfUEqqVM",
  awsid: 'AKIARNPIYR52UQ45ZB6M',
  awssecret: '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',
  awsregion: 'us-east-1',
};
