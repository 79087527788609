<ng-container *ngIf="($userProfile | async); let profile">
    <header class="p-3 border-bottom">
        <div class="d-flex align-items-center">
            <div class="jackpot-logo">
                <a [routerLink]="'assets'" class="d-flex align-items-center mb-2 mr-2 mb-lg-0 link-body-emphasis text-decoration-none">
                    <img src="https://vh-vault.s3.us-east-1.amazonaws.com/static/jp-logo.png" alt="Jackpot" width="42" height="42" class="me-2 " >
                    <span class="label text-uppercase" style="font-weight: 800;">Jackpot</span>
                </a>
            </div>
            <div class="jackpot-nav" *ngIf="brand">
                <div class="container">
                    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        
                        <mat-form-field  class="brand-selector mx-3" *ngIf="($brands | async); let brands">            
                            <mat-select (selectionChange)="activateBrand()" [(value)]="brand">
                                <!-- <mat-option selected [value]="profile.company.id">{{ profile.company.name }}</mat-option> -->
                                <mat-option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                        <li><a routerLinkActive="active" [routerLink]="'dashboard'" class="nav-link px-2 ">Dashboard</a></li>
                        <li><a routerLinkActive="active" [routerLink]="'assets'" class="nav-link px-2">Assets</a></li>
                        <li><a routerLinkActive="active" [routerLink]="'collections'" class="nav-link px-2 ">Collections</a></li>          
                        </ul>
        
                        <!-- <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
                        <input type="search" class="form-control" placeholder="Search..." aria-label="Search">
                        </form> -->
        
                        <div class="dropdown text-end" (click)="openSettingsMenu()">
                            <button  mat-button class="d-block  text-decoration-none dropdown-toggle">
                                <img [src]="cdn+profile.user.photo" alt="mdo" width="32" height="32" class="rounded-circle">
                            </button>
                            <!-- <ul class="dropdown-menu text-small">
                                <li><a class="dropdown-item" href="#">New project...</a></li>
                                <li><a class="dropdown-item" href="#">Settings</a></li>
                                <li><a class="dropdown-item" href="#">Profile</a></li>
                                <li><hr class="dropdown-divider"></li>
                                <li><a class="dropdown-item" href="#">Sign out</a></li>
                            </ul> -->
                        </div>
                        <app-settings-menu [open]="openSettings"></app-settings-menu>
                    </div>
                </div>
            </div>
        </div>

    </header>
</ng-container>