import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  AssetCategoryModel, DialogData, DialogFiles, FileModel, NerveUploadProgress } from 'src/app/_models/subjects';
import {  FileShell } from 'src/app/_services/file.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import {ErrorStateMatcher} from '@angular/material/core';
import { CategoryService } from 'src/app/_services/category.service';
import { Observable, first, map, tap } from 'rxjs';
import { AssetService } from '../../../_services/asset.service';
import { BrandService } from '../../../_services/brand.service';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-asset-upload-dialog',
  templateUrl: './asset-upload-dialog.component.html',
  styleUrl: './asset-upload-dialog.component.scss'
})
export class AssetUploadDialogComponent {
  pendingUploads: FileShell[] = [];
  form: FormGroup;
  formAssets: FormArray;
  matcher = new MyErrorStateMatcher();
  $categories: Observable<AssetCategoryModel[]>;
  constructor(
    public dialogRef: MatDialogRef<AssetUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogFiles,
    private vs: VelvetysoftService,
    private snack: MatSnackBar,
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private assetService: AssetService,
    private brandService: BrandService,
  ) {
    this.data.fileShells = this.data.fileShells || [];
    this.form = this.fb.group(this.assetFields());
    this.formAssets = this.fb.array([]);    
  }

  //@TODO: DELETED FILES SHOULD BE REMOVED FROM THE FORM

  ngOnInit() {   
    //remove categories
    // GET BRAND FOR ID
    // GET CATEGORIES FOR BRAND
    // GET FEILDS FOR CATEGORY Brand
    this.brandService.$activeBrand
      .pipe(first())
      .subscribe((brand) => {
       //ASIGN BRAND TO FORM
        this.form.patchValue({bid: brand.id});
    });
    this.$categories = this.categoryService.categories()
      .pipe(map( data => 
        data
          .filter((item) => item.active === true )
          .filter((item) => item.name !== 'All')
      ));
              
    this.data.services.fileService.whenFilesAdded()
      .subscribe((files:FileModel[]) => 
        files.forEach((fileShell:FileModel) => {      
          //ADD FILE SHELLS TO FORM
          this.makeAssetFields(fileShell);      
          //ADD FILE SHELLS UI Listing
          return this.data.fileShells.push(fileShell)
        }));

    //DIRECT LISTENER
    this.data.services.fileService
      .listenForUploadProgress()
      .subscribe(this.listenForUploadProgress.bind(this));

    //INDIRECT LISTENER
    // this.data.services.io.isConnected 
    // && this.data.services.io.uploadProgress()
    //   .subscribe((event:NerveUploadProgress) => {           
    //      this.data.fileShells.forEach( (fileShell, index) => {
    //       console.log('cool',fileShell.ufi, event.id);
    //       this.updateProgress(fileShell, index, event) 
    //     });                
    //   });

    this.processPendingUploads();
  }

  /**
  * PENDING UPLOADS
  * 
  */
  //FORMAT PENDING UPLOADS IF USER WANTS THEM
  processPendingUploads(){
    if(this.data.pendingUploads){
      for (let key in this.data.pendingUploads.uploads) {
        const item = JSON.parse(this.data.pendingUploads.uploads[key]);            
        console.log('PENDING UPLOADS', item);
        this.pendingUploads.push(new FileShell(item, this.form.value.bid));        
      }      
    }
  }

  //QUE THEM FOR UPLOAD
  quePending(){
    this.data.fileShells = this.pendingUploads;
    this.pendingUploads = [];
    this.data.fileShells.map((item) => {
      this.makeAssetFields(item);      
    });
    //console.log(this.formAssets);
  }
  //END PENDING UPLOADS

  //UPLOAD PROGRESS
  listenForUploadProgress(data:any){
    console.log('UPLOAD PROGRESS', data);
    const { ufi, progress, speed, status, error, path } = data;    
    const _fileShell = this.data.fileShells.find( (fs) => fs.ufi === ufi);

    if (_fileShell) {      
      if(speed) _fileShell.speed = (speed)
      if(status) _fileShell.status = status;
      if(error) _fileShell.error = error;
      if(path) _fileShell.path = path;

      if(progress){
        const percent = Math.round(progress.loaded / progress.total * 100);
        _fileShell.progress = percent;        
      }

      _fileShell.speed = (speed)
        ? `${this.vs.size(speed)}/s`
        : null;

        this.updateShellForm(_fileShell);

    }    
  }

  // THESES ARE THE FIELDS FOR EACH ASSET,
  // GENERATED WHEN THE UPLOAD STARTES
  //THEY WILL GET PATCHED AGAIN WHEN COMPLETE
  makeAssetFields(fileShell:FileModel){
   
    let _assetFields = this.modifyRequiredFields(this.assetFields());
    const fg = this.fb.group({..._assetFields, ...this.fileFields()});    
    fg.patchValue({ ...fileShell } as Partial<{ [x: string]: unknown; }>); // Fix: Convert fileShell to a partial object
    //PATCH BRAND ID
    fg.patchValue({bid: this.form.value.bid});
    this.formAssets.push(fg);
  }

  modifyRequiredFields(fields:any){
    //remove required validation
    for (let key in fields) {      
      let _field = fields[key];
      _field[1] = null;
    }    
    return fields;
  }

  log(item:any){
    console.log(item);
  }

  updateShellForm(fileShell:FileModel){
    this.formAssets.controls.forEach((control, i) => {
      if(control.value.ufi === fileShell.ufi){
        control.patchValue(fileShell);
      }
    });
  }

  fileFields() {
    return {      
      path: ['', Validators.required],
      ufi: ['', Validators.required],
      title: ['', Validators.required],
      size: ['', Validators.required],
      ext: ['', Validators.required],
      bid: ['', Validators.required],
    }
  }

  assetFields() {
    return {
      category: ['', Validators.required],    
      bid: ['', Validators.required],  
    }
  }

  // updateProgress(fileShell:FileModel, index:number, event:any){
  //   if(event.id === fileShell.ufi){
  //     fileShell.progress = event.progress
  //     fileShell.progressStatus = event.action || null;
  //     fileShell.status = event.status || null;
  //     fileShell.path = event.path || null;
  //     fileShell.speed = (event.speed)
  //       ? `${this.vs.size(event.speed)}/s`
  //       : null;

  //     //IMPORTANT COMPLETE GIVE PATH
  //     if(event.action === 'complete'){
  //       //UPDATE FORM without using index
  //       this.formAssets.controls.forEach((control, i) => {
  //         if(control.value.ufi === event.id){
  //           control.patchValue({path: event.path});
  //         }
  //       });
  //     }
  //   }
  // }

  cancel(){
    this.dialogRef.close();
  }
  
  submit(){
    //PATCH GENERAL FORM VALUES TO EACH FORM ASSETS
    this.formAssets.controls.forEach((control, i) => {
      control.patchValue(this.form.value);
    });

    if(this.form.valid && this.formAssets.valid){
      console.log('FORM', this.formAssets.value);
      // fill this.form values into each formAssets      
      this.assetService
        .add(this.formAssets.value)
        .pipe(first())
        .subscribe((data:any) => {
          //PROCESS WILL GO TO VELVETYSOFT 
          //THEN VS WILL BROACAST TO NERV TO PROCESS THE ASSET
          console.log('ASSET SERVICE', data);
          this.dialogRef.close(this.form.value);
      });;
      //
    }

  }

  delete(fileShell:FileShell,){
    //find the index of this fileShell
    const index = this.data.fileShells.indexOf(fileShell);
    this.formAssets.removeAt(index);
    this.data.services.io.deletePendingFile(fileShell.ufi).subscribe((data:any) => {      
      if(this.pendingUploads.length > 0) 
        this.pendingUploads = this.pendingUploads.filter((item) => item.ufi !== fileShell.ufi);
      if(this.data?.fileShells?.length > 0) 
        this.data.fileShells = this.data.fileShells.filter((item) => item.ufi !== fileShell.ufi);
      this.snack.open(`${data.message}`, 'Close', { duration: 3000});
    });
  }

  ngOnDestroy(){
    this.pendingUploads = this.data.pendingUploads;
    console.log('DESTROYED');
  }
}

