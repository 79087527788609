
<div class="d-flex flex-nowrap">

    <app-assets-sidebar [pager]="assetspager"></app-assets-sidebar>

    <div class="assets-content" (scroll)="onScroll($event)">
        <ng-container *ngIf="($assets | async); let pager;" >
            
            <div class="results-toolbar my-3">            
                <div class="container">
                    <div class="row m-0 align-items-center">
                        <div class="col-sm-3">
                            <mat-form-field>
                                <mat-label>Search</mat-label>
                                <input matInput type="search"  [(ngModel)]="search" (input)="onSearch()">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <button mat-icon-button (click)="prev()" color="primary"><mat-icon>chevron_left</mat-icon> </button>
                            <button mat-icon-button (click)="next()" color="primary"><mat-icon>chevron_right</mat-icon> </button>
                        </div>
                        <div class="col-sm-2 label">
                            {{ pager.current_page }} of {{ pager.last_page }}  ({{ pager.total }} total)
                        </div>
                        <div class="col-sm-2">
                            <app-loader size="24px" [class.invisible]="!loading"></app-loader>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container"  #assetContent >
                <div [class.loading]="loading" class="row align-items-center">     
                    <ng-container *ngIf="pager.data.length > 0; else noResults">
                        <div class="col-sm-3 asset" *ngFor="let asset of pager.data">
                            <asset [asset]="asset" ></asset>
                        </div>
                    </ng-container>       
                </div>    
            </div>
            
        </ng-container>
    </div>

</div>

<ng-template #noResults>
    <div class="px-4">
        No Assets Found
    </div>
</ng-template>

<ng-template #loader>
    <div class="text-center">
        <div class="mx-auto d-inline-flex align-items-center justify-content-center">
            <mat-spinner  style="display: inline-block;width: 32px;height: 32px;" color="primary" class="mx-auto "></mat-spinner> <span class="p-2 ">Loading...</span>
        </div>
    </div>
</ng-template>
