<div class="asset">
    <div class="margins">                    
        <div class="asset-wrap">
            <div class="">
                <div class="asset-overlay d-flex align-items-center justify-content-center">
                    <div class="asset-overlay-inner">
                        <button class="asset-btn" color="white" (click)="enlarge(asset)" mat-icon-button><mat-icon>zoom_in</mat-icon></button>
                        <button class="asset-btn" color="white" (click)="edit(asset)" mat-icon-button><mat-icon>edit</mat-icon></button>
                        <button class="asset-btn" color="white" (click)="download(asset)" mat-icon-button><mat-icon>cloud_download</mat-icon></button>
                    </div>
                </div>
                <div class="asset-preview">
                    <div class="spacer"></div>
                    <img *ngIf="src; else no" class="asset-img" [ngSrc]="src" fill alt="{{ asset.title }}">
                    <ng-template #no>
                        <div class="no-img d-flex justify-content-center align-items-end">
                            <div>
                                <div class="no-icon d-flex align-items-center justify-content-center"><mat-icon>image</mat-icon></div>
                                <div class="no-ext"><div>{{ asset.ext }}</div></div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>                                            
        </div>
        <div class="asset-text my-1">{{ asset.title }}</div>
    </div>
</div>