<ng-container *ngIf="($userProfile | async); let profile">
    <ng-container *ngIf="($brand | async); let brand">
        <div class="settings-menu" [class.open]="open">
            <div class="p-4">
                <h3 class="color-white">Settings</h3>
            </div>
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div>
                                <div class="label text-uppercase color-white">Company Settings</div>  
                                <div class="label-subtle">{{ profile.company.name }}</div>  
                            </div>
                        </mat-panel-title>            
                    </mat-expansion-panel-header>
                    <ul class="nav nav-pills flex-column mb-auto">
                        <li class="nav-item">
                            <button (click)="open=!open" [routerLink]="'/settings/dashboard'" class="nav-link text-white"><mat-icon>payments</mat-icon> Dashboard</button>
                            <button (click)="open=!open" [routerLink]="'/settings/subscription'" class="nav-link text-white"><mat-icon>payments</mat-icon> Subscription</button>
                            <button (click)="open=!open" [routerLink]="'/settings/billing'" class="nav-link text-white"><mat-icon>payments</mat-icon> Billing</button>
                        </li>
                    </ul>
                </mat-expansion-panel>

                <mat-expansion-panel 
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div>
                                <div class="label text-uppercase color-white">Brand Settings</div>  
                                <div class="label-subtle">{{ brand.name }}</div>  
                            </div>
                        </mat-panel-title>           
                    </mat-expansion-panel-header>
                    <ul class="nav nav-pills flex-column mb-auto">
                        <li class="nav-item">
                            <button (click)="open=!open" [routerLink]="'/settings/categories'" class="nav-link text-white"><mat-icon>category</mat-icon> Categories</button>
                        </li>
                    </ul>
                </mat-expansion-panel>

                <mat-expansion-panel 
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div>
                                <div class="label text-uppercase color-white">Profile Settings</div>  
                                <div class="label-subtle">{{ profile.user.name }} {{ profile.user.last_name }}</div>  
                            </div>
                        </mat-panel-title>           
                    </mat-expansion-panel-header>
                    <p>I'm visible because I am open</p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </ng-container>
</ng-container>