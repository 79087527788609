
<h1 mat-dialog-title><app-prefix [model]="form.value"></app-prefix>Category</h1>

<mat-dialog-content class="">    
    <div class="alert alert-danger" *ngIf="form.errors">{{ form.errors }}</div>
    
    <form [formGroup]="form">    
        <mat-form-field>
            <mat-label>Category Name</mat-label>
            <input apperance="outline" matInput placeholder="Name" formControlName="name" name="name" required>
            <!-- <mat-error *ngIf="f.name.errors?.required">Category Name is required</mat-error> -->
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions >
  <button mat-button mat-dialog-close (click)="cancel()">Cancel</button>
  <button mat-button color="primary" (click)="save()"  cdkFocusInitial>Save</button>
</mat-dialog-actions>