import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  model: any = {};
  error: any = false;
  submitted = false;
  loading = true;
  forceReset = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,    
    private snackBar: MatSnackBar,
  ){}

  ngOnInit() {
    if( this.authService.isLoggedIn()){
      this.router.navigate(['/assets']);      
    }else{
      this.loading = false;
    }

    this.route.queryParamMap.subscribe(params => {
      console.log(params,params.get("email"));
      this.model.email = params.get("email");
      this.forceReset = (params.get("action") == 'reset') ? true : false;
    });
    // this.formErrors.errors.subscribe(errors => {  
    //   this.formErrors.display(errors, err => this.alertService.error(err) );      
    // })    
  }

  login() {
    //this.alertService.clear();
    this.submitted = true;
    this.model.action = 'login';
    this.authService.loginForm(this.model).subscribe(response => {      
      this.loading = false;
      if (response.token) {
        this.authService.setUser(response);
      }else{
        //this.alertService.error('A Spooky error has occured...');
        this.snackBar.open('A Spooky error has occured...', 'Close', {});
      }
    }, errRes => {
      this.submitted = false;
      console.log(errRes)
      //this.alertService.error(errRes.error.message);
      this.snackBar.open(errRes.error.message, 'Close', {});
    });
  }

  forgot(){
    //this.alertService.clear();
    this.authService.forgotPassword(this.model).subscribe(res => {
      //this.alertService.success();
      if (res.status) this.snackBar.open(res.message);
    });
  }
}
