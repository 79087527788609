import { Component, Input, SimpleChanges } from '@angular/core';
import { AssetService } from '../../../_services/asset.service';
import { Observable, Subject, first, switchMap } from 'rxjs';
import { AssetModel } from '../../../_models/subjects';


export interface DetailsData {
  asset: any;
  action: string;
}


@Component({
  selector: 'app-asset-details',  
  templateUrl: './asset-details.component.html',
  styleUrl: './asset-details.component.scss'
})



export class AssetDetailsComponent {
  @Input() open: any;
  @Input() data: DetailsData;
  @Input() assetService: AssetService;
  $results: Subject<any> = new Subject<any>();

  constructor(    
  ) { }

  ngOnInit(): void {
    this.load(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      this.load(changes['data'].currentValue);      
    }
  }

  makeThumb(asset: AssetModel){
    this.assetService.remakeThumb(asset).subscribe( data => {
      console.log(data);
    });
  }

  load(data: {asset: any, action: string}){
    if(!data){
      return;
    }
    if(data.action == "log"){        
      this.assetService.log(this.data.asset)
        .pipe(first())
        .subscribe(data => this.$results.next(this.decodeLog(data.data)));
    }else{
      this.assetService.details(this.data.asset)
        .pipe(first())
        .subscribe(data => this.$results.next(data));
    }
  }

  display(logItem: any){
    let status = Object.keys(logItem)[0];
    let data = Object.values(logItem)[0];

    return (status == "false")
      ? "Error: " + data
      : "Successful";
  }

  decodeLog(log: any){
    for (const field in log) {
      if (log.hasOwnProperty(field)) {
        const value = log[field];
        try {
          const parsedValue = JSON.parse(value);
          log[field] = parsedValue;
        } catch (error) {
          log[field] = value;
        }
      }
    }
    return log;
  }
}
