import { Component, ElementRef, ViewChild } from '@angular/core';
import { Observable, Subject, debounceTime, tap } from 'rxjs';
import { AssetModel, BrandModel, Pager, PagerObject, UserProfileModel } from 'src/app/_models/subjects';
import { AssetService } from 'src/app/_services/asset.service';
import { BrandService } from 'src/app/_services/brand.service';
import { environment } from 'src/enviorments/enviorment';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent {
    $userProfile: Observable<UserProfileModel>;
    $assets:Observable<Pager<AssetModel>>;
    assetspager: PagerObject;

    //MOVE TO COMPONENT
    url: string = '';
    data: any = {};
    search: string = '';
    loading: boolean = true;
    assetPager2: any;
    private $search = new Subject<any>();

    query: Pager<AssetModel>;
    @ViewChild('assetContent', {static: false}) assetContent: ElementRef;
    cdn = environment.cdn;
    constructor(
      private assetService: AssetService,
    ) { }
  
    ngOnInit(): void {

      
      this.assetspager = this.assetService.pager2<AssetModel>();
      this.$assets = this.assetspager.$source.pipe(tap( (assets:any) => {
        this.query = assets
        this.loading = false;
      }));
      // this.assetPager2.nextPage();
      
      // console.log(this.assetPager2);

      // this.assetspager = this.assetService.pager<AssetModel>();      
      // this.$assets = this.assetspager.$source.pipe(tap(assets => {
      //   this.query = assets
      //   this.loading = false;
      // }));

      this.$search.pipe(
        debounceTime(400))
        .subscribe((event) => {          
          this.loading = true;
          console.log('Search',event);
          //this.assetspager.query(event.data,event.url,event?.options) 
        });
    }

    onScroll(event: any) {      
      //@TODO HARD CODED HEIGHT , FIX
      let frame = event.target.scrollTop+window.innerHeight-60;
      if(frame >= event.target.scrollHeight) 
        this.assetspager.nextPage({options:{persist:true}});
      
    }

   

    doQuery(data?:any, url?:string, options?:any){      
      // this.assetspager.query({
      //   filters: data,
      //   path: url,
      //   options: options
      // }); 
      //this.assetspager.loadSearch({},this.query.next_page_url);
    }

    
    next(){
      //console.log('Next',this.query.next_page_url);
      //this.doQuery({},this.query.next_page_url)
      this.assetspager.nextPage({options:{persist:true}});
    }

    prev(){
      //this.doQuery({},this.query.prev_page_url)
    }

    onSearch(){
      //DEBOUNCE THIS
      this.assetspager.query({filters:{search:this.search}});
      //this.doQuery({search:this.search})
    }

    ngDestroy(){
      this.$search.complete();
    }
    

}
