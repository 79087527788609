import { AnimationTriggerMetadata, animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { AssetModel } from 'src/app/_models/subjects';
import { AssetService } from 'src/app/_services/asset.service';
import { environment } from 'src/enviorments/enviorment';

@Component({
  selector: 'asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent {
  cdn = environment.cdn;
  assetcdn = environment.assetcdn;
  src: string|null = null;
  
  @Input() asset: AssetModel;

  constructor(
    private assetService: AssetService,
  ) { }


  ngOnInit(): void {
    this.src = this.assetService.thumb(this.asset);
  }

  enlarge(asset:AssetModel){
    this.assetService.enlarge(asset);
  }

  edit(asset:AssetModel){
  }
  

  download(asset:AssetModel){
    this.assetService.downloadAsset(asset);
  }
}
