import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandModel, UserProfileModel } from 'src/app/_models/subjects';
import { BrandService } from 'src/app/_services/brand.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-subscription',  
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss'
})
export class SubscriptionComponent {
  $brand: Observable<BrandModel>;
  $userProfile: Observable<UserProfileModel>;
  constructor(
    private brandService: BrandService,
    private userService: UserService
  ) { }

  ngOnInit(): void {  
    this.$userProfile = this.userService.userProfile;
    //this.$brand = this.brandService.brand;
  }
}
