import { Component } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscribable, Subscription, takeUntil } from 'rxjs';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { BrandModel, BrandOverviewModel, UserProfileModel } from 'src/app/_models/subjects';
import { AssetService } from 'src/app/_services/asset.service';
import { BrandService } from 'src/app/_services/brand.service';
import { CategoryService } from 'src/app/_services/category.service';
import { UserService } from 'src/app/_services/user.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { delay } from 'rxjs/operators';

interface RangeModel {
  key: string;
  label: string;
};

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrl: './dashboard-settings.component.scss'
})


export class DashboardSettingsComponent {
  activeBID: number|null = null;
  $brand: Observable<BrandModel>;
  $overview: Observable<BrandOverviewModel> | Observable<any>;
  $userProfile: Observable<UserProfileModel>;
  rangeChangeSubject = new ReplaySubject<RangeModel>(1);
  brand: BrandModel;  
  ranges: RangeModel[];
  range: any;
  $hugeChain: Subscription;
  $dashDataRef = new Subject<any>();    
  
  constructor(
    private brandService: BrandService,
    private vs: VelvetysoftService,
    private userService: UserService
  ) { 
    this.ranges = this.vs.ranges();
    this.range = this.ranges.find( (r: RangeModel) => r.key == '1m');
  }

  ngOnInit(): void {  
    this.$userProfile = this.userService.userProfile;
    this.$overview = this.loadDash();
    this.rangeChangeSubject.next(this.range);
  }

  //UGLY!!!
  loadDash():Observable<BrandOverviewModel> {       
    this.$dashDataRef.next({loading: 'loading...'})   
    this.$hugeChain = this.rangeChangeSubject.pipe(
      switchMap( (range) => {
        this.$dashDataRef.next({loading: 'loading...'})
        return this.getBrandInfo(range);    
      })).subscribe( (overview:BrandOverviewModel) => {
        //set a delya for prettyness
        setTimeout(() => {
          this.$dashDataRef.next(overview);
        }, 400);
      });
    return this.$dashDataRef.asObservable();
  }

  getBrandInfo(range:RangeModel):Observable<BrandOverviewModel>{
    
    return this.brandService.activeBrand.pipe(
      switchMap( (brand:BrandModel) => {
        this.brand = brand;        
        this.activeBID = brand.id;       
        return this.brandService.brandOverview(this.range.key, true);
      })); 
  }

  ngOndestroy(){
    this.brandService.$activeBrand.unsubscribe();
    this.$hugeChain.unsubscribe();
  }

  setRange(key:string){
    this.range = this.ranges.find( (r: { key: string; }) => r.key == key);    
    this.rangeChangeSubject.next(this.range);
    //this.loadDash();
    //this.$overview = this.brandService.brandOverview(this.range.key);
  }

}

