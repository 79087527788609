import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { TrendModule } from 'ngx-trend';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './_components/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule} from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ApiService } from './_services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { AssetsComponent } from './_components/assets/assets.component';
import { MainComponent } from './_components/main/main.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { IConfig } from './_models/subjects';
import { environment } from '../enviorments/enviorment';
import { AssetComponent } from './_components/assets/asset/asset.component';
import { NgOptimizedImage } from '@angular/common';
import { SidebarComponent } from './_components/assets/sidebar/sidebar.component';
import { HeaderComponent } from './_components/header/header.component';
import { AssetEnlargeComponent } from './_components/assets/asset-enlarge/asset-enlarge.component';
import { nervImgPipe, FilterByPropertyPipe, SizePipe, SortByPipe } from './pipes/pipes.pipe';
import { MenuComponent } from './_components/settings/menu/menu.component'
import { MatExpansionModule} from '@angular/material/expansion';
import { PrefixTitleComponent } from './_directives/prefix-title/prefix-title.component';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { CategoriesComponent } from './_components/settings/categories/categories.component';
import { LoaderComponent } from './_components/loader/loader.component';
import { SubscriptionComponent } from './_components/settings/subscription/subscription.component';
import { MatMenuModule } from '@angular/material/menu';
import { MomentModule } from 'ngx-moment';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { CollectionsComponent } from './_components/collections/collections.component';
import { DashboardSettingsComponent } from './_components/settings/dashboard-settings/dashboard-settings.component';
import { AddCategoryComponent } from './_components/settings/categories/add-category/add-category.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IoService } from './_services/io.service';
import { ConfirmComponent } from './_components/dialogs/confirm/confirm.component';
import { AssetUploaderComponent } from './_components/assets/asset-uploader/asset-uploader.component';
import { AssetUploadDialogComponent } from './_components/assets/asset-upload-dialog/asset-upload-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIcon } from '@angular/material/icon';
import { AssetDetailsComponent } from './_components/assets/asset-details/asset-details.component';




export function jwtTokenGetter() {  
  return localStorage.getItem('token');
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
const config: SocketIoConfig = { url: environment.io, options: {
  transportOptions: {
    polling: {
      extraHeaders: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    },
  },
}};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AssetsComponent,
    MainComponent,
    AssetComponent,
    AssetDetailsComponent,
    SidebarComponent,
    PrefixTitleComponent,    
    HeaderComponent,
    ConfirmComponent,
    CategoriesComponent,
    AssetUploadDialogComponent,
    SubscriptionComponent,
    AddCategoryComponent,
    AssetUploaderComponent,
    AssetEnlargeComponent,    
    FilterByPropertyPipe,
    nervImgPipe,
    SizePipe,
    SortByPipe,
    MenuComponent,
    DashboardSettingsComponent,
    LoaderComponent,
    DashboardComponent,
    CollectionsComponent
  ],
  imports: [
    BrowserModule,    
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatProgressBarModule,    
    MatIcon,
    DragDropModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MomentModule,
    MatProgressSpinnerModule,
    MatInputModule,
    TrendModule,
    MatIconModule,
    MatDialogModule,
    AppRoutingModule,
    NgOptimizedImage,
    HttpClientModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    SocketIoModule.forRoot(config),
    //NgxMaskModule.forRoot(options),
  ],
  providers: [ApiService, IoService],
  bootstrap: [AppComponent]
})
export class AppModule { }


