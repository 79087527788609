<div class="d-flex">
    <div class="label-subtle" style="color:#e0ddfb;">Processing Assets</div>
    <button mat-icon-button (click)="dismiss()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="d-flex">
    <div *ngIf="files">
        <div *ngFor="let file of files">
            <div class="label overflow-ellipsis">{{ file.asset.title }}</div>
            <div>{{ file.message}}</div>
            <div *ngIf="file.error">{{ file.error.message }}</div>
        </div>
    </div>
    <button mat-icon-button (click)="dismiss()">
        <mat-icon>close</mat-icon>
    </button>
</div>