import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-prefix',  
  templateUrl: './prefix-title.component.html',
  styleUrl: './prefix-title.component.scss'
})
export class PrefixTitleComponent {
  @Input() model: any;

  constructor() { }
}
