<ng-container *ngIf="($brand | async); let brand; else loader">
    <div class="d-flex flex-nowrap">
        <div class="offset"></div>
        <div class="container mt-4">
            <h3>{{ brand.name }} - Categories</h3>
            <hr>

            <div *ngIf="($categories | async)">
                <div class="mb-5">
                    <h4 class="label text-uppercase">Global Categories</h4>
                    <div *ngFor="let category of categoriesGlobal | sortBy: 'order'" class="my-2 d-flex align-items-center">
                            <mat-slide-toggle (change)="activeCategory(category)" class="me-2" color="primary" [(ngModel)]="category.active" ></mat-slide-toggle> {{ category.name }} 
                    </div>
                </div>

                <div class="mb-5">
                    <div class="mb-3">
                        <h4 class="label text-uppercase">Custom Categories</h4>      
                        <div class="col-sm-7">
                            <p class="p-subtle">Try to create broad categories for better organization.</p>
                        </div>    
                        
                        <div cdkDropList (cdkDropListDropped)="drop($event)">
                            <div cdkDrag *ngFor="let category of categories | sortBy: 'order'" class="my-2 m-0 align-items-center row">
                                <div class="col-sm-1">
                                    <mat-icon style="cursor: grab;">drag_handle</mat-icon>
                                </div>                           
                                <div class="col-sm-3">
                                    <mat-slide-toggle (change)="activeCategory(category)" class="me-3" color="primary" [(ngModel)]="category.active" ></mat-slide-toggle> 
                                    &nbsp;
                                    <span class="me-3">{{ category.name }} </span>
                                </div>
                                <div class="col-sm-1">
                                    <button class="mat-normal" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon class="mat-normal">more_vert</mat-icon>
                                    </button>
                                    <mat-menu class="menu-normal" #menu="matMenu">
                                        <button mat-menu-item (click)="edit(category)"><mat-icon>edit</mat-icon> Edit</button>
                                        <button mat-menu-item (click)="delete(category)"><mat-icon>delete</mat-icon> Delete</button>
                                    </mat-menu>
                                </div>
                                    <!-- <button mat-icon-button class="btn-icon"><mat-icon>edit</mat-icon></button>
                                    <button mat-icon-button class="btn-icon"><mat-icon>delete</mat-icon></button> -->
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <button (click)="add()" mat-stroked-button color="primary"><mat-icon>add</mat-icon> Category</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loader>
    <app-loader></app-loader>
</ng-template>