import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, EMPTY, ReplaySubject, Subject, Subscription, first, map, switchMap, take, tap } from 'rxjs';
import { AssetCategoryModel, BrandModel, DialogData, NervEventResponse} from '../_models/subjects';
import { BrandService } from './brand.service';
import { IoService } from './io.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddCategoryComponent } from '../_components/settings/categories/add-category/add-category.component';
import { ConfirmComponent } from '../_components/dialogs/confirm/confirm.component';

export interface queryCats {
  bid: number;
  force?: boolean|null;
}

export interface AssetCategoryBrandModel {
  bid: number;
  category: AssetCategoryModel;
}

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  public $categories: BehaviorSubject<AssetCategoryModel[]> = new BehaviorSubject<AssetCategoryModel[]>([]);
  private $bRef: Subscription;
  private $ioRef: Subscription;
  private _cacheBID: number = 0;
  private SYSTEM:string = 'HTTP';
  private IOID:string = 'AssetCategory';
  private $actions: Subject<any>|null = null;
  constructor(
    private api: ApiService,
    private brandService: BrandService,    
    private io: IoService,
    private dialog: MatDialog
  ) {
    this.$bRef = this.brandService.activeBrand.subscribe((brand:BrandModel) => {      
      this._cacheBID = brand.id;
      this.loadCategories({bid: brand.id});
    });
    this.$ioRef = this.io.$status.pipe( 
      switchMap(res => {        
        this.SYSTEM = (res.status) ? 'IO' : 'HTTP';              
        return (this.SYSTEM == 'IO')
          ? this.io.listen<AssetCategoryModel>(this.IOID,this.$categories)
          : EMPTY;        
    }),
    map(() => {})
    ).subscribe();
  }

  refresh(){
    if(this.SYSTEM == 'HTTP') this.loadCategories({bid: this._cacheBID});
  }

  //CATEGORIES, maybe move them?
  categories(){        
    return this.$categories.asObservable();
  }

  action<T>(path:string,data:any,options?:any){
    let ref = new Subject();
    this.api.post<T>(path,data)
      .pipe(first())
      .subscribe( res =>{
         ref.next(res);
        if(options?.action == 'refresh') this.refresh();
      });

    return ref.asObservable();
  }
  

  loadCategories(data:queryCats) {
    this.api.post<AssetCategoryModel[]>("assets-categories",data)
      .pipe(first())
      .subscribe( res=> this.$categories.next(res));
  }

  add(category:AssetCategoryModel){
    let options = { action: 'refresh' }
    return this.action<AssetCategoryBrandModel>('assets-category',{
      bid: this._cacheBID,
      category: category
    },options);    
  }

  activeCategory(category:AssetCategoryModel){
    let data = {
      bid: this._cacheBID,
      category: category
    };

    let options = { action: 'refresh' }
    return this.action<AssetCategoryBrandModel>('assets-categories-active',data,options);    
  }

  deleteCategory(category:AssetCategoryModel){
    return this.action('assets-category-delete',{id: category.id});    
  }

  updateOrder(data:any){
    return this.action('assets-categories-order',data);    
  }

  ngOnDestroy() {
    this.$bRef.unsubscribe();
  }

  remove(AssetCategory: AssetCategoryModel){
    let options = { action: 'refresh' }
    return this.action<AssetCategoryBrandModel>('assets-categories-delete',{
      id: AssetCategory.id,      
    },options);    
  }

  delete(category:AssetCategoryModel){
    let resultRef = new Subject();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = <DialogData> { 
      model: category, 
      services: { categoryService: this },
      type: 'delete',
      title: `Delete Category ${category.name}`,
      content: `Are you sure you want to delete the category ${category.name}? This will delete the category from all Brands on your account. You can disable the category by per Brand by unchecking the active box.`,
      action: 'delete'
    };
    dialogConfig.id = 'edit-modal';
    const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result:any) => {
        this.remove(result.model)
        if(result?.status) resultRef.next(result.model);
      });
    return resultRef;
  }

  edit(category?:AssetCategoryModel){
    let resultRef = new Subject();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = <DialogData> { 
      model: category, 
      services: { categoryService: this },
      type: 'asset'
    };
    // //DIALOG CONFIG
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = false;
    // dialogConfig.autoFocus = false;        
    // dialogConfig.data = {};
   
    // dialogConfig.data[this.subject] = subject;
    // dialogConfig.data[`${this.subject}Service`] = this;   
    // dialogConfig.data['options'] = options;
    dialogConfig.id = 'edit-modal';
    const dialogRef = this.dialog.open(AddCategoryComponent, dialogConfig);
    
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result:any) => {
        this.add(result.model)
        if(result?.status) resultRef.next(result.model);
      });
    return resultRef;
  }



  destroy(){
    this.$bRef.unsubscribe();
    this.$ioRef.unsubscribe();
    this.$actions?.unsubscribe();
  }
}
