import { Component, } from '@angular/core';
import { FileService } from '../../../_services/file.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { AssetProcessingModel } from '../../../_models/subjects';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-processing',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, CommonModule],
  templateUrl: './processing.component.html',
  styleUrl: './processing.component.scss'
})
export class ProcessingComponent {

  //files: <AssetProcessingModel[]> = []
  files: AssetProcessingModel[] = [];

  constructor(
    public FileService: FileService,
    public dialogRef: MatSnackBarRef<ProcessingComponent>
  ) {
    this.FileService.AssetProcessing.subscribe((data:AssetProcessingModel) => {
      //add files to the list unless they are already there, then update the variables in the list
      const index = this.files.findIndex((file:AssetProcessingModel) => file.id === data.id);
      if(index === -1){
        this.files.push(data);
      } else {
        this.files[index] = data;
      }      
    
    });
  }

  dismiss(){
    this.dialogRef.dismiss();
  }
}
