import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/_models/subjects';

@Component({
  selector: 'app-confirm',  
  templateUrl: './confirm.component.html',
  styleUrl: './confirm.component.scss'
})
export class ConfirmComponent {  
  
  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  cancel(){
    this.dialogRef.close();
  }
  
  save(){
  }

  delete(){
    this.dialogRef.close({
      status: true,
      model: this.data.model
    });
  }
}
