import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetCategoryModel, BrandModel, UserProfileModel } from 'src/app/_models/subjects';
import { BrandService } from 'src/app/_services/brand.service';
import { CategoryService } from 'src/app/_services/category.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-settings-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  @Input() open: any;
  panelOpenState = false;  
  $categories: Observable<AssetCategoryModel[]>;
  $userProfile: Observable<UserProfileModel>;
  $brand: Observable<BrandModel>;
  constructor(
    private categoryService: CategoryService,
    private brandService: BrandService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.$brand = this.brandService.$activeBrand;
    this.$userProfile = this.userService.userProfile;
    this.$categories = this.categoryService.categories();
  }
}
