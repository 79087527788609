<div class="flag">
    <img src="https://vh-vault.s3.us-east-1.amazonaws.com/static/vh-icon.png" alt="Velvet Hammer Branding">
  </div>
  
  <div class="d-flex h-100 align-items-center">
    <div class="container">
      <div class="text-center " *ngIf="!loading; else loader">      
        
        <div class="row justify-content-center ">      
          <div class="login-form p-4">         
            <div>
              <img src="https://vh-vault.s3.us-east-1.amazonaws.com/static/showpony-logo.png" alt="Show Pony" class="mb-3" style="max-width: 180px;" />
            </div>

            <form (ngSubmit)="login()" #form="ngForm" class="box-shadow ">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput placeholder="Email" type="email"  name="email" 
                    [(ngModel)]="model.email" required />
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field class="w-100 mr-2">
                  <input matInput placeholder="Password" type="password"  name="password"
                    placeholder="Password" [(ngModel)]="model.password" required />
                </mat-form-field>
              </div>
              <button mat-flat-button color="primary" type="submit" [disabled]="submitted || !form.valid">Get In</button>
              <a mat-button color="primary" (click)="forgot()" >Reset Password</a>
            </form>        
          </div>
        </div>
      </div>
  
      <ng-template #loader>
        <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
      </ng-template>
    </div>
  </div>