import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { FileModel } from 'src/app/_models/subjects';
import { FileService } from 'src/app/_services/file.service';

@Component({
  selector: 'app-asset-uploader',  
  templateUrl: './asset-uploader.component.html',
  styleUrl: './asset-uploader.component.scss'
})
export class AssetUploaderComponent {
  @ViewChild('files') filesRef: any;
  @Input() files: FileModel[];
  constructor(
    private fileService: FileService
  ) { }

  triggerUpload(event: any) {
    this.filesRef.nativeElement.click(event);
    this.filesRef.nativeElement.value = null;
  }

  uploadFiles(event: any) {    
    this.fileService.addFiles(event.target.files); 
  }
}
