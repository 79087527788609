import { Injectable } from '@angular/core';
import { environment } from 'src/enviorments/enviorment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, catchError, concatMap, first, forkJoin, map, switchMap, throwError } from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FileModel, NervResponse, NervResponseImg, nervFileUploadMultiPart } from '../_models/subjects';
import { DomSanitizer } from '@angular/platform-browser';
import { Form } from '@angular/forms';
import { FileService } from './file.service';

export interface VSR {
  status: Boolean
  data: any
  errors: any
}

export function jwtTokenGetter() {    
  return localStorage.getItem('token');
}

export interface ApiOptions {
  skipToast?: boolean
  skipGlobalErrorHandler?: boolean
  hideMessage?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api = environment.backend;  
  nerv = environment.nerv;
  _options:ApiOptions = {
    skipToast: false,
    skipGlobalErrorHandler: false,
    hideMessage: false,
  }

  constructor(
    private http: HttpClient,
    private snack: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {}
  
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": [`Bearer ${jwtTokenGetter()}`]
    }),
  };


  httpOptionsSimple = {
    headers: new HttpHeaders({
      "Authorization": [`Bearer ${jwtTokenGetter()}`]
    }),
  };

  //skipToast?: boolean, skipGlobalErrorHandler?: boolean
  post<T>(path:string, data:any, options?:ApiOptions ) {    
    let _options = {...this._options, ...options};
    return this.http
      .post<VSR>(`${this.api}${path}`, data, this.httpOptions)
      .pipe(
        catchError((res) => {
          if (!_options.skipGlobalErrorHandler) this.handleErrors(res, _options);
          return throwError(res);
        }),
        map((r) => this.result(r,_options))
      );
  }

  get<T>(path:string, options?:ApiOptions) {    
    let _options = {...this._options, ...options};
    return this.http.get<VSR>(`${this.api}${path}`, this.httpOptions).pipe(
      catchError((res) => {
        
        if (!options?.skipGlobalErrorHandler) this.handleErrors(res, _options);
        return throwError(res);
      }),
      map((r) => this.result(r,_options))
    );
  }
  
  handleErrors(res:any, options:any) {
    if(res?.error?.message) this.snack.open(`${res.error.message}`, "Dismiss", { duration: 2000 });
    console.log(res);
  }

  result(res:any,options:ApiOptions) {
    if (res.message && !options.hideMessage)
      this.snack.open(`${res.message}`, "Dismiss", {
        duration: 2000,
      });
    return res.status ? res.data : res;
  }

  /*nervDirectUpload(data:any, options?:ApiOptions, multi?:boolean) {
    const _self = this;
    const { files, fileShells, formData, fileService } = data;

    let _options = {...this._options, ...options};
    

    //GET PRESIGNED URLS FOR FILES
    const fileData:any = { fileShells: fileShells };



    //GE PARTS
    if(multi){
      fileData.multi = 'true';

      //PARTS
      
      const itenerary = (async () => {
        return this.http.post(`${this.nerv}upload-assets-direct`, fileData, this.httpOptionsSimple).toPromise();    
      })();

      itenerary.then((res: any) => {

        const _itenerary: nervFileUploadMultiPart[] = res.data;

        _itenerary.forEach((fileItenerary: nervFileUploadMultiPart) => {

          const uploads: Observable<any>[] = [];

          //GET THE SHELL AND ACTAUL FILE
          const _shell = fileShells.find((fileShell: FileModel) => fileShell.ufi === fileItenerary.ufi);          

          //ASSIGN THE ENDPOINT TO fileShell
          fileItenerary.parts.forEach((fpart: { partNumber: number, url: string }) => 
            _shell.fileParts
              .map( (spart:any) =>{
                if(spart.part === fpart.partNumber) spart.url = fpart.url;              
              })
          );

          _shell.fileParts.forEach((part:any) => {
          
            const upload = this.http.put(part.url, part.file,{
              reportProgress: true,
              observe: 'events'
            })
            uploads.push(upload);
          });


            

          forkJoin(uploads).subscribe((res:any) => {
            const complete = fileItenerary.parts.find( (p:any) => p.partNumber == 'complete' )
            if(complete)
              this.http.post(complete.url,{headers:{"Content-Type": "",}}).subscribe((res:any) => {
                console.log(res);
              })
          });

            // const complete = fileItenerary.parts.find( (p:any) => p.partNumber == 'complete' )
            // //@ts-ignore
            // _shell.fileParts.push({part: 'complete', url: complete.url, file: null});

          });




        return res;
      });
      
    }else{
      //NOT MULIT
      const $urls = this.http.post(`${this.nerv}upload-assets-direct`, fileData, this.httpOptionsSimple);    
      const $uploads = $urls.pipe(
        switchMap((res: any) => {
          const uploads: Observable<any>[] = [];
      
          res.data.forEach((file: { ufi: string, status: boolean, presignurl: string }, index: number) => {
            const _shell = fileShells.find((fileShell: FileModel) => fileShell.ufi === file.ufi);
      
            //CREATE REQUEST FOR EACH
            Object.keys(files).forEach(key => {
              const currentFile = files[key];
              if (currentFile.name === _shell.title) {
                //const formData = new FormData();      
                //formData.append('file', currentFile); // Pass the actual file from the FileModel object     
                                
                const upload = this.http.put(file.presignurl, currentFile,{
                  reportProgress: true,
                  observe: 'events'
                })
                //console.log(fileService);

                const startTime = Date.now();        

                const calculateAndSendSpeed = (progress:any) => {
                    const currentTime = Date.now();
                    const elapsedTime = currentTime - startTime;
                    const uploadSpeed = (progress.loaded || 0) / (elapsedTime / 1000); // Upload speed in bytes per second
                    // Send upload speed over WebSocket
                    return uploadSpeed;
                };      

                if(fileService) upload.subscribe( (progress:any) => {
                  const speed = calculateAndSendSpeed(progress);
                  if(progress.loaded) fileService.addForUploadProgress({progress, ufi: _shell.ufi, speed: speed, status: 'uploading'});
                }, (err:any) => {

                  fileService.addForUploadProgress({ 
                    ufi: _shell.ufi, 
                    status: err.statusText, 
                    error: true});
                    
                  if(err?.message) _self.snack.open(`${err.message}`, "Dismiss", { duration: 2000 });                
                });
                uploads.push(upload);
              }
            });
          });
      
          return forkJoin(uploads);
        })
      )

      if($uploads) $uploads.subscribe((res:any) => {
        console.log(res);
      }, (err:any) => {
        console.log('all error',err);
        if(err?.message) _self.snack.open(`${err.message}`, "Dismiss", { duration: 2000 });
      });
    }

    
  }*/

  /*nervFileUpload(files:FormData, options?:ApiOptions) {    
    let _options = {...this._options, ...options};
    let $result = new BehaviorSubject(null);
    // $result.next({
    //   image: 'https://vh-vault.s3.us-east-1.amazonaws.com/static/loader.svg',
    //   loading: true
    // });
    this.http.post(`${this.nerv}upload-assets`, files, 
      this.httpOptionsSimple).pipe(
        //@ts-ignore
        catchError((res:NervResponse) => {
          console.log(res);
          if (!_options.skipGlobalErrorHandler) this.handleErrors(res, _options);
          return this.handleErrors(res, _options);
        }),
        map((r:NervResponse) => {
          console.log(r);
          (r.status) 
            ? this.result(r,_options)
            : this.handleErrors(r ,_options);
        })
      ).subscribe((res: any) => {        
        $result.next(res);
      });

    return $result;

  }*/

  pager<T>(path:string, data:any, options?:any) {    
    const _self = this;
    const $source:ReplaySubject<T> = new ReplaySubject(1);      
    let _originalPath = path;
    let _filters:any = null;
    let _lastPath:any = null;

    // Load data
    const load = function(url:string, options?:any){
        let path = _lastPath = (url) ? url.replace(_self.api,"") : url;
        _self.post(path,{})
          .pipe(first())
          .subscribe(pager => {

            (options?.persist) 
              ? $source.pipe(first())
                  .subscribe( previousPager => { 
                    console.log("persist")
                    //copy the object to avoid reference
                    let _pager = {...pager}
                    
                    //@ts-ignore
                    _pager.data = [...previousPager.data, ...pager.data]
                    $source.next(_pager)
                })
              : $source.next(pager)            
          });
    }
    //Search data
    const loadSearch = function(filters:any, url?:string, options?:any){
      
      //check if the url contains '?'
      let operator = (url && url.includes('?'))
        ? '&'
        : '?';

      _filters = (filters) 
        ? `${operator}${_self.paramatersToString(filters)}`
        : null;

      let path = (url) 
        ? `${url}${_filters}`
        : `${_originalPath}${_filters}`;

      filters && load(path, options);                  
    }

    const params = function(){
      return _filters;
    }

    const refresh = function(){
      let path = (_lastPath)
        ? _lastPath
        : _originalPath;
      load(path);
    };

    //short hand if else statment with no return

    (data)
      ? loadSearch(data)
      : load(path);

    return {
      load: load,
      refresh: refresh,
      loadSearch: loadSearch,
      getParams: params,
      $source: $source
    }
  }






  paramatersToString(vars:any):string{
    let params = new HttpParams();
    for (const f in vars) {
      if(vars[f]) params = params.set(f,vars[f]);
    };
    return params.toString();
  }

  //NERV
  nervImg(path:string){    
    let $result = new ReplaySubject(1);
    $result.next({
      image: 'https://vh-vault.s3.us-east-1.amazonaws.com/static/loader.svg',
      loading: true
    });
    this.http.get(`${this.nerv}image/${path}`, { 
          // responseType: 'blob', 
          // observe: 'response', 
          headers: new HttpHeaders({
            "Authorization": [`Bearer ${jwtTokenGetter()}`],
          })
        }).pipe(
          //@ts-ignore
          catchError((res) => {
            $result.next({
              image: 'https://vh-vault.s3.us-east-1.amazonaws.com/static/404.png?1'              
            });            
        })).subscribe((res: NervResponseImg) => { 
          let img = this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${res.image}`)
          res.image
          $result.next({
            image: img,
            ...res.info
          }); 
        });
        
    return $result;
      //`${this.vs.nerv}image/${asset.path}?max=1920`
  }


}


