<ng-container *ngIf="(url | nervImg | async); let nerv" >
    <div         
        class="asset-preview-modal" [ngClass]="{'window-loading': nerv.loading}">
            <div class="color-white" *ngIf="nerv?.gallery">{{nerv?.gallery?.current}} of {{nerv?.gallery?.pages}} </div>            
            <button *ngIf="nerv?.gallery?.prev" (click)="gallery(nerv.gallery.prev)"  color="white" mat-raised-button class="asset-btn asset-prev"><mat-icon class="" >chevron_left</mat-icon></button>

            <ng-container *ngIf="isVideo">
                <video 
                    *ngIf="($videoPreview | async); let video;"
                    style="max-width: 100%;"
                    class="asset-preview"               
                    [attr.src]="video"
                    controls>
                </video>
              </ng-container>

            <ng-container *ngIf="!isVideo">
                <img                         
                    class="asset-preview" 
                    [ngClass]="{noThumb: !data.model.hasThumb, 'gthumb': data.model.g_id, 'loading': nerv.loading}"             
                    [attr.src]="nerv.image">
            </ng-container>
            
            <ul class="nav nav-pills flex-column mb-auto nav-float">
                <li class="mb-1">
                    <button mat-flat-button (click)="meta('log')" color="white">Log</button>                    
                </li>
                <li class="mb-1">
                    <button mat-flat-button (click)="meta('details')" color="white">Details</button>
                </li>
            </ul>
            <button *ngIf="nerv?.gallery?.next" (click)="gallery(nerv.gallery.next)"  color="white" mat-raised-button class="asset-btn asset-next"><mat-icon class="" >chevron_right</mat-icon></button>
    </div>

    

    <div *ngIf="!nerv.loading" class="py-3 d-flex align-items-center justify-content-between">
        <div class="title-shadow">
            <span class="desc mr-1">{{ data.model.title }}</span>
            &nbsp; | &nbsp;
            <span class="size">Size: {{ size(data.model.size) }}</span>
            &nbsp; | &nbsp;
            <span class="size">Type: {{ data.model.ext }}</span>
        </div>
        <div>
            <button class="mx-1" color="white" (click)="download(data.model)" mat-button><mat-icon>cloud_download</mat-icon> &nbsp; Download</button>
            <button class="mx-1" color="white" (click)="edit(data.model)" mat-button><mat-icon>edit</mat-icon> &nbsp; Edit</button>
        </div>
    </div>

    <app-asset-details 
        *ngIf="!nerv.loading" 
        [data]="detailsData" 
        [open]="detailsOpen"
        [assetService]="data.services.assetService"></app-asset-details>
</ng-container>

