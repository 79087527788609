
<h1 mat-dialog-title>{{ data.title }}</h1>

<mat-dialog-content class="">    
    {{ data.content }}
</mat-dialog-content>

<mat-dialog-actions >
  <button mat-button mat-dialog-close (click)="cancel()">Cancel</button>
  <button *ngIf="data.action == 'save'" mat-button color="primary" (click)="save()" [mat-dialog-close]="true" cdkFocusInitial>Save</button>
  <button *ngIf="data.action == 'delete'" mat-button color="warn" (click)="delete()" [mat-dialog-close]="true" cdkFocusInitial>Delete</button>
</mat-dialog-actions>