<ng-container *ngIf="($userProfile | async); let profile">
    <ng-container *ngIf="($overview | async); let overview; else loader">    
        <div class="d-flex flex-nowrap" >
            <div class="offset"></div>
            <div class="container mt-4">
                <h3>{{ profile.company.name }}</h3>
                <hr>            

                <div class="row d-flex" *ngIf="!overview.loading; else loader">
                    <div class="col-sm-4">
                        <div class="tile p-4">
                            <div class="row justify-content-between">
                                <div class="col-sm-6">
                                    <span class="label">Assets</span>
                                </div>
                                <div class="col-sm-6 ">
                                    <button mat-button [matMenuTriggerFor]="menu" >
                                        {{ range.label }} <mat-icon>expand_more</mat-icon>
                                    </button>
                                </div>
                                <mat-menu #menu>
                                    <button mat-menu-item (click)="setRange('1d')">1 Day</button>
                                    <button mat-menu-item (click)="setRange('1w')">1 Week</button>
                                    <button mat-menu-item (click)="setRange('1m')">1 Month</button>
                                    <button mat-menu-item (click)="setRange('3m')">3 Months</button>
                                    <button mat-menu-item (click)="setRange('6m')">6 Months</button>
                                    <button mat-menu-item (click)="setRange('1y')">1 Year</button>
                                </mat-menu>
                            </div>

                            <div>
                                <span class="value">{{ overview?.assets }} <span class="label-subtle">{{ overview?.space | size }} of {{ overview?.spaceTotal | size }}</span></span>
                                <ngx-trend 
                                    *ngIf="overview"
                                    [gradient]="['#42b3ff', '#925fff']"
                                    [strokeWidth]="3"
                                    [autoDraw]="true"
                                    [autoDrawDuration]="3000"
                                    [radius]="20"
                                    [smooth]="true"
                                    [data]="overview?.assetsByDay">
                                </ngx-trend>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="tile p-4">
                            <span class="label">Dash 2</span>
                            <div></div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="tile p-4">
                            <span class="label">Dash 3</span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #loader>
    <app-loader></app-loader>
</ng-template>